import styled from 'styled-components/macro';
import { Layout, Divider } from 'antd';
import { BREAKPOINTS, media } from '@app/styles/themes/constants';

interface LayoutMain {
  $hasSidebar?: boolean;
}

export const LayoutMaster = styled(Layout)`
  height: 100vh;
`;

export const LayoutMain = styled(Layout)<LayoutMain>`
  overflow: hidden;

  @media only screen and (${media.xl}) {
    margin-left: unset;
  }

  .header--logo {
    height: 5rem;
    padding: 10px;
    z-index: 3;

    @media screen and (min-width: ${BREAKPOINTS.xl + 1}px) {
      width: 140px;
    }

    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      height: 3rem;
      padding: 0;
    }
  }
`;

export const SplitDivider = styled(Divider)`
  margin: 0;
`;

export const Content = styled.div`
  // height: 100%;
`;
