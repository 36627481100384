import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { BASIC_PATH } from '@app/components/router/AppRouter';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from '@app/utils/utils';
import { FaAngleDown } from 'react-icons/fa';
import { BiGlobe } from 'react-icons/bi';
import { HiOutlineHome, HiOutlineStar, HiOutlineCog } from 'react-icons/hi';
import { MdOutlineShield } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getFeedbacks } from '@app/store/slices/feedbackSlice';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
  isCollapsed: boolean;
}

interface SidebarNavigationItem {
  id: string;
  label: React.ReactNode;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  disabled: boolean;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed, isCollapsed }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userInfo.user);
  const dispatch = useAppDispatch();
  const { feedbacks } = useAppSelector((state) => state.feedback);
  const feedbackLength = feedbacks.filter((item) => item.approve === 'pending').length;

  useEffect(() => {
    dispatch(getFeedbacks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarNavigation: SidebarNavigationItem[] = [
    {
      label: <Link to="">{t('sidebar.dashboard', { defaultValue: 'Home' })}</Link>,
      id: 'dashboard_button',
      key: 'dashboard',
      url: '/',
      icon: <HiOutlineHome className="sidemenuIcon" />,
      disabled: false,
    },
    {
      label: <Link to="journeys">{t('sidebar.journeys', { defaultValue: 'My Journeys' })}</Link>,
      url: '/journeys',
      id: 'journeys_button',
      key: 'journeys',
      icon: <BiGlobe className="sidemenuIcon" />,
      disabled: false,
    },
    // {
    //   label: <Link to="profile">{t('sidebar.profile')}</Link>,
    //   url: '/profile',
    //   key: 'profile',
    //   icon: <UserOutlined />,
    // },
    {
      label: (
        <Link to="favorites" className="breakWords">
          {t('sidebar.favorites', { defaultValue: 'Favorite Services' })}
        </Link>
      ),
      url: '/favorites',
      id: 'favorites_button',
      key: 'favorites',
      icon: <HiOutlineStar className="sidemenuIcon" />,
      disabled: false,
    },
    {
      label: (
        <a
          href={`https://transparency-dashboard-td-dev.k8s.across-h2020.eu/admin/dashboard?lang=${getCurrentLanguage()}`}
          target="_self"
          className="breakWords"
        >
          <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>{t('common.transparency', { defaultValue: 'Transparency Dashboard' })} </span>
            {!isCollapsed && <FaAngleDown className="tdIcon" style={{ marginLeft: '4px', fontSize: '16px' }} />}
          </span>
        </a>
      ),
      id: 'transparency_dash_button',
      key: 'TD',
      icon: <MdOutlineShield className="sidemenuIcon" />,
      disabled: false,
    },
    {
      label: (
        <>
          <Link to="admin" className="breakWords">
            {t('sidebar.admin', { defaultValue: 'Admin' })}
            <div className="admin-notification">{feedbackLength === 0 ? '' : feedbackLength}</div>
          </Link>
        </>
      ),
      url: '/admin',
      id: 'admin_button',
      key: 'admin',
      icon: <HiOutlineCog className="sidemenuIcon" />,
      //NOT READY -- change role citizen to admin - when it's ready
      disabled: user?.admin_role ? false : true,
    },
  ];

  const sidebarNavFlat = sidebarNavigation.reduce(
    (result: SidebarNavigationItem[], current) =>
      result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );

  const currentMenuItem = sidebarNavFlat.find(({ url }) => {
    const link = location.pathname.split(BASIC_PATH).pop();
    return url === (link ? link : '/');
  });

  const selectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  return (
    <S.Menu
      key={'sideMenu'}
      mode="inline"
      selectedKeys={selectedKeys}
      onClick={() => setCollapsed(true)}
      items={sidebarNavigation}
    />
  );
};

export default SiderMenu;
