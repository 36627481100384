import styled from 'styled-components/macro';
import { Anchor, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { BREAKPOINTS, media, SIDER_SIZES } from '@app/styles/themes/constants';
import { LAYOUT } from '@app/styles/themes/constants';
import { Button as ButtonComponent } from '@app/components/common/buttons/Button/Button';

export const Sider = styled(Layout.Sider)`
  position: relative;
  overflow: visible;
  right: 0;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;
  /* border-right: var(--border-light-color); */
  box-shadow: var(--box-shadow);
  background-color: var(--sider-background-color);
  color: var(--text-secondary-color);
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: ${BREAKPOINTS.xl - 1}px) {
    left: 0;
    position: fixed !important;
  }
`;

export const SiderContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - ${LAYOUT.mobile.headerHeight} - ${SIDER_SIZES.siderButton});

  @media only screen and (${media.md}) {
    max-height: calc(100vh - ${LAYOUT.desktop.headerHeight} - ${SIDER_SIZES.siderButton});
  }
  height: 100%;
`;

export const SiderLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const SiderLogoDiv = styled.div`
  height: ${LAYOUT.mobile.headerHeight};
  padding: ${LAYOUT.mobile.headerPadding};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (${media.md}) {
    height: ${LAYOUT.desktop.headerHeight};
    padding-top: ${LAYOUT.desktop.paddingVertical};
    padding-bottom: ${LAYOUT.desktop.paddingVertical};
  }
`;

export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--white);
`;

export const LinkWrapper = styled(Anchor.Link)`
  padding: 0;
  height: ${SIDER_SIZES.siderButton};
  word-break: break-word;
  display: flex;
  align-items: center;

  a {
    color: var(--text-sider-secondary-color);
    white-space: pre-line;
    padding: 0 5px;
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    margin-left: -10px;
    &:hover {
      color: var(--lightgrey);
    }
  }
`;

export const ButtonWrapper = styled(ButtonComponent)`
  padding: 0;
  height: ${SIDER_SIZES.siderButton};
  width: 100%;
  color: var(--text-sider-secondary-color);
  span {
    display: flex !important;
    justify-content: end !important;
  }
  &:hover {
    color: var(--text-secondary-color);
  }
`;
