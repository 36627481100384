import { createAction, createAsyncThunk, createSelector, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { IAction, ICost, IMetadata, IServicesStatsInfo, ITouchPoint, IWorkflow } from '@app/interfaces/interfaces';
import {
  getCostServiceApi,
  getMetadataServiceApi,
  getServiceCountApi,
  getServicesApi,
  getTimeServiceApi,
} from '@app/api/http/timeCostMetadataService.api';
import { uniqBy } from 'lodash';
import { AppDispatch, RootState } from '../store';

export interface ServicesState {
  cost: { serviceId: string; value: ICost }[];
  loadingCost: string | null;
  time: { serviceId: string; value: string }[];
  loadingTime: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: { serviceId: string; value: IMetadata }[];
  loadingMetadata: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceCount: { value: any };
  serviceCountLoading: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services: any;
}

const initialState: ServicesState = {
  cost: [],
  loadingCost: null,
  time: [],
  loadingTime: null,
  metadata: [],
  loadingMetadata: null,
  serviceCount: { value: {} },
  serviceCountLoading: null,
  services: [],
};

// Cost actions
const setLoadingCost = createAction<PrepareAction<string>>(
  'servicesMetadataInfo/setLoadingCost',
  (serviceId: string) => {
    return {
      payload: serviceId,
    };
  },
);

const fetchServiceCostInfo = createAsyncThunk(
  'servicesMetadataInfo/fetchServiceCostInfo',
  async (serviceId: string, { dispatch, rejectWithValue }) => {
    dispatch(setLoadingCost(serviceId));
    return getCostServiceApi(serviceId)
      .then((res) => ({ serviceId, value: res.data }))
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

// Time actions
const setLoadingTime = createAction<PrepareAction<string>>(
  'servicesMetadataInfo/setLoadingTime',
  (serviceId: string) => {
    return {
      payload: serviceId,
    };
  },
);

const fetchServiceTimeInfo = createAsyncThunk(
  'servicesMetadataInfo/fetchServiceTimeInfo',
  async (serviceId: string, { dispatch, rejectWithValue }) => {
    dispatch(setLoadingTime(serviceId));
    return getTimeServiceApi(serviceId)
      .then((res) => ({ serviceId, value: res.data }))
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

// Metadata actions
const setLoadingMetadata = createAction<PrepareAction<string>>(
  'servicesMetadataInfo/setLoadingMetadata',
  (serviceId: string) => {
    return {
      payload: serviceId,
    };
  },
);

export const fetchServiceMetadataInfo = createAsyncThunk(
  'servicesMetadataInfo/fetchServiceMetadataInfo',
  async (serviceId: string, { dispatch, rejectWithValue }) => {
    dispatch(setLoadingMetadata(serviceId));
    return getMetadataServiceApi(serviceId)
      .then((res) => ({ serviceId, value: res.data }))
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

// Metadata actions
const setLoadingCount = createAction<PrepareAction<string>>('serviceCountInfo/setLoadingCount', () => {
  return { payload: 'Service Count Loading' };
});

export const fetchServiceCountInfo = createAsyncThunk(
  'serviceCountInfo/fetchServiceCountInfo',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoadingCount());
    return getServiceCountApi()
      .then((res) => {
        return { value: res.data };
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);
export const fetchServicesInfo = createAsyncThunk(
  'servicesInfo/fetchServicesInfo',
  async (workflows: IWorkflow[], { rejectWithValue }) => {
    const services = new Set<string>();
    if (workflows?.length > 0) {
      workflows.forEach((w) => {
        if (w.jsonfile) {
          const decodedJsonString = Buffer.from(w.jsonfile, 'base64').toString('utf-8');
          const decodedJsonObject = JSON.parse(decodedJsonString);
          const phases = decodedJsonObject?.phases;
          const actions: IAction[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          phases.forEach((phase: any) => {
            phase.actions.forEach((action: IAction) => {
              actions.push(action);
            });
          });

          actions.forEach((action: IAction) => {
            if (action.touchpoints?.length > 0) {
              action.touchpoints.forEach((tp: ITouchPoint) => {
                services.add(tp.service_id);
              });
            }
          });
        }
      });
    }

    return getServicesApi()
      .then((res) => {
        const servicesData = res.data
          .filter((serv: IServicesStatsInfo) => services.has(serv.identifier))
          .map((item: IServicesStatsInfo) => ({
            description: item.hasInfo.description,
            isPublicService: item.isPublicService,
            spatial: item.hasInfo.spatial,
            title: item.title,
          }));

        return { servicesData };
      })
      .catch((error) => {
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        );
      });
  },
);

// Cost & Time & Metadata selectors
const selectServiceMetadata = (state: RootState) => ({
  cost: state.timeCostMetadata.cost,
  loadingCost: state.timeCostMetadata.loadingCost,
  time: state.timeCostMetadata.time,
  loadingTime: state.timeCostMetadata.loadingTime,
  metadata: state.timeCostMetadata.metadata,
  loadingMetadata: state.timeCostMetadata.loadingMetadata,
  serviceCount: state.timeCostMetadata.serviceCount,
  serviceCountLoading: state.timeCostMetadata.serviceCountLoading,
  services: state.timeCostMetadata.services,
});

export const selectServiceMetadataInfo = createSelector(
  [selectServiceMetadata, (_, serviceId: string) => serviceId],
  ({ cost, loadingCost, time, loadingTime, metadata, loadingMetadata }, serviceId) => ({
    cost: cost.find((x) => x.serviceId === serviceId)?.value || null,
    time: time.find((x) => x.serviceId === serviceId)?.value || null,
    metadata: metadata?.find((x) => x.serviceId === serviceId)?.value || null,
    loadingCost: loadingCost === serviceId,
    loadingTime: loadingTime === serviceId,
    loadingMetadata: loadingMetadata === serviceId,
  }),
);

export const selectServiceCount = createSelector([selectServiceMetadata], ({ serviceCount, serviceCountLoading }) => ({
  serviceCount,
  serviceCountLoading,
}));

export const selectServices = createSelector([selectServiceMetadata], ({ services }) => ({
  services,
}));

// Cost & Time actions
export const fetchTimeCostMetadataService =
  (serviceId: string) =>
  (dispatch: AppDispatch): void => {
    dispatch(fetchServiceCostInfo(serviceId));
    dispatch(fetchServiceTimeInfo(serviceId));
    dispatch(fetchServiceMetadataInfo(serviceId));
  };

export const timeCostMetadataSlice = createSlice({
  name: 'timeCostMetadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Cost Service
    builder.addCase(setLoadingCost, (state, action) => {
      state.loadingCost = action.payload;
    });
    builder.addCase(fetchServiceCostInfo.fulfilled, (state, action) => {
      state.cost = uniqBy([action.payload, ...state.cost], 'serviceId');
      state.loadingCost = null;
    });
    builder.addCase(fetchServiceCostInfo.rejected, (state) => {
      state.loadingCost = null;
    });
    // Time Service
    builder.addCase(setLoadingTime, (state, action) => {
      state.loadingTime = action.payload;
    });
    builder.addCase(fetchServiceTimeInfo.fulfilled, (state, action) => {
      state.time = uniqBy([action.payload, ...state.time], 'serviceId');
      state.loadingTime = null;
    });
    builder.addCase(fetchServiceTimeInfo.rejected, (state) => {
      state.loadingTime = null;
    });
    // Metadata Service
    builder.addCase(setLoadingMetadata, (state, action) => {
      state.loadingMetadata = action.payload;
    });
    builder.addCase(fetchServiceMetadataInfo.fulfilled, (state, action) => {
      state.metadata = uniqBy([action.payload, ...state.metadata], 'serviceId');
      state.loadingMetadata = null;
    });
    builder.addCase(fetchServiceMetadataInfo.rejected, (state) => {
      state.loadingMetadata = null;
    });
    // Service Count
    builder.addCase(setLoadingCount, (state, action) => {
      state.serviceCountLoading = action.payload;
    });
    builder.addCase(fetchServiceCountInfo.fulfilled, (state, action) => {
      state.serviceCount = action.payload;
      state.serviceCountLoading = null;
    });
    builder.addCase(fetchServiceCountInfo.rejected, (state) => {
      state.serviceCountLoading = null;
    });
    builder.addCase(fetchServicesInfo.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.services = (action.payload as { servicesData: any }).servicesData;
    });
    builder.addCase(fetchServicesInfo.rejected, (state) => {
      state.services = [];
    });
  },
});

export default timeCostMetadataSlice.reducer;
