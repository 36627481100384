import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

export const putFavoriteServiceApi = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  serviceId: string,
): Promise<AxiosResponse> =>
  httpApi.put(`favorite/${workflowInstanceId}/${phaseId}/${actionId}/${encodeURIComponent(serviceId)}`);

export const getFavoriteServiceApi = (): Promise<AxiosResponse> => httpApi.get(`favorite/all`);
export const getFavoriteJourneyServiceApi = (workflowInstanceId: string): Promise<AxiosResponse> =>
  httpApi.get(`favorite/${workflowInstanceId}`);
