import styled from 'styled-components/macro';
import { BREAKPOINTS } from '@app/styles/themes/constants';

export const ReferencesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  flex-wrap: nowrap;
  padding: 10px;
  background: var(--layout-sider-bg-color);
  z-index: 1;

  .policy {
    margin-left: 10px;
  }

  .policyLeft {
    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
      margin-right: 55px;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
  font-size: 12px;
`;
