import styled from 'styled-components/macro';
import { Popover as AntPopover } from 'antd';

export const Popover = styled(AntPopover)`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
