import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '@app/store/slices';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { notificationController } from '@app/controllers/notificationController';

/**
 * Log a warning and show a toast!
 */
const errorLoggingMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) && !action.type.includes('workflows/createUserWorkflow')) {
    notificationController.error(action.payload);
  }

  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorLoggingMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
