import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { withLoading } from '@app/hocs/withLoading.hoc';
import ProtectedLayout from './ProtectedLayout';
import MainLayout from '../layouts/main/MainLayout/MainLayout';
import { useResponsive } from '@app/hooks/useResponsive';

// Pages
import Error404Page from '@app/pages/404/Error404Page';
//import LoginPage from '@app/pages/login/LoginPage';
import AdminPage from '@app/pages/admin/AdminPage';
import { useKeycloak } from '@react-keycloak/web';
import { Loading } from '../common/Loading';
import { getUserInfo } from '@app/utils/utils';
import { isEmpty, isNil } from 'lodash';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setUser } from '@app/store/slices/userInfoSlice';
import { get } from 'local-storage';

// Pages (lazy-loading)
const DashboardPage = React.lazy(() => import('@app/pages/dashboard/DashboardPage'));
const JourneysPage = React.lazy(() => import('@app/pages/journeys/JourneysPage'));
const JourneyPage = React.lazy(() => import('@app/pages/journey/JourneyPage'));
const FavoritesPage = React.lazy(() => import('@app/pages/favorites/FavoritePage'));
const CreateJourneyPage = React.lazy(() => import('@app/pages/createJourney/CreateJourneyPage'));
const PreLoginPage = React.lazy(() => import('@app/pages/login/LoginPage'));

const Dashboard = withLoading(DashboardPage);
const Journeys = withLoading(JourneysPage);
const Journey = withLoading(JourneyPage);
const Favorites = withLoading(FavoritesPage);
const CreateJourney = withLoading(CreateJourneyPage);
const Login = withLoading(PreLoginPage);

export const BASIC_PATH = 'app';
export const AppRouter: React.FC<{ location?: Location }> = () => {
  const user = useAppSelector((state) => state.userInfo.user);
  const { initialized, keycloak } = useKeycloak();
  const { isTablet } = useResponsive();
  const { authenticated, tokenParsed } = keycloak;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialized && authenticated && !isEmpty(tokenParsed)) {
      const user = getUserInfo(tokenParsed);
      if (!isNil(user)) dispatch(setUser(user));
    }
  }, [dispatch, initialized, authenticated, tokenParsed]);

  useEffect(() => {
    if (!authenticated && !isNil(user)) dispatch(setUser(null));
  }, [dispatch, user, authenticated]);

  const pathname = location?.pathname;
  const domain = window.location.hostname;

  const handleVArendering = () => {
    if (pathname && (pathname === '/login' || pathname === '/')) {
      // eslint-disable-next-line
      (window as any).xuic?.showUserChat(false);
    } else {
      if (isTablet) {
        // eslint-disable-next-line
        (window as any).xuic?.showUserChat(true);
      } else {
        // eslint-disable-next-line
        (window as any).xuic?.showUserChat(false);
      }
    }
  };

  useEffect(() => {
    handleVArendering();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, domain, isTablet]);

  return !initialized ? (
    <Loading size="large" />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate to={get<string>('redirectUrl') || BASIC_PATH} replace />} />
        <Route path={BASIC_PATH} element={<ProtectedLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="journeys" element={<Journeys />} />
          <Route path="journeys/:workflowInstanceId" element={<Journey />} />
          <Route path="create_new_journey" element={<CreateJourney />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route path="favorites" element={<Favorites />} />
          <Route path="*" element={<Error404Page />} />
          <Route path="admin" element={<AdminPage />} />
        </Route>
        <Route path="login" element={<MainLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="*" element={<Error404Page full />} />
      </Routes>
    </BrowserRouter>
  );
};
