import { getUserProfileApi, postUserProfileApi } from '@app/api/http/profile.api';
import { PersonModel } from '@app/interfaces/interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface PersonState {
  person: PersonModel | null;
  loading: boolean;
  loadingPost: boolean;
}

const initialState: PersonState = {
  person: null,
  loading: false,
  loadingPost: false,
};

export const getUserProfile = createAsyncThunk('user/getPerson', async (_, { rejectWithValue }) =>
  getUserProfileApi()
    .then((res) => res.data)
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const postUserProfile = createAsyncThunk('user/setPerson', async (person: PersonModel, { rejectWithValue }) =>
  postUserProfileApi(person)
    .then((res) => res.data)
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.person = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserProfile.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postUserProfile.pending, (state) => {
      state.loadingPost = false;
    });
    builder.addCase(postUserProfile.fulfilled, (state, action) => {
      state.person = action.payload;
      state.loadingPost = false;
    });
    builder.addCase(postUserProfile.rejected, (state) => {
      state.loadingPost = false;
    });
  },
});

export default personSlice.reducer;
