import React, { useEffect, useState } from 'react';
import EUFlag from '@app/assets/images/eu-flag.jpg';
import { Col, Modal, Row } from 'antd';
import * as S from './References.styles';
import { P2 } from '../typography/P2/P2';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyText from './PrivacyPolicy/PrivacyPolicyText';
import { BREAKPOINTS } from '@app/styles/themes/constants';
import Overlay from '../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import { useLocation } from 'react-router-dom';
import { Button } from '@app/components/common/buttons/Button/Button';
import CodeOfConductText from './CodeOfConduct/CodeOfConductText';

interface ReferencesProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

export const References: React.FC<ReferencesProps> = ({ isCollapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const [policyOpened, setPolicyOpened] = useState<boolean>(false);
  const [conductOpened, setConductOpened] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { largerScreen, mobileOnly, smallScreen } = useResponsive();
  const toggleSider = () => setCollapsed(!isCollapsed);
  const location = useLocation();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isSignedIn = location?.pathname !== '/login' && !(location.pathname === '/app' && location.state?.from);

  return (
    <>
      <Modal
        title={t('common.modals.privacyPolicyTitle', { defaultValue: 'ACROSS Platform Privacy Policy' })}
        open={policyOpened}
        onCancel={() => setPolicyOpened(false)}
        maskClosable={false}
        footer={
          <Row align="middle" justify="end">
            <Col>
              <Button key="Cancel" size="small" onClick={() => setPolicyOpened(false)}>
                {t('common.modals.close', { defaultValue: 'Close' })}
              </Button>
            </Col>
          </Row>
        }
        width={windowWidth < BREAKPOINTS.sm ? '90vw' : '80vw'}
        style={{ minWidth: '400px', height: '80vh', overflowY: 'scroll' }}
      >
        <PrivacyPolicyText />
      </Modal>
      <Modal
        title={t('common.modals.codeOfConductTitle', {
          defaultValue: 'ACROSS Platform Code of Conduct for Service Providers ',
        })}
        open={conductOpened}
        onCancel={() => setConductOpened(false)}
        maskClosable={false}
        footer={
          <Row align="middle" justify="end">
            <Col>
              <Button key="Cancel" size="small" onClick={() => setConductOpened(false)}>
                {t('common.modals.close', { defaultValue: 'Close' })}
              </Button>
            </Col>
          </Row>
        }
        width={windowWidth < BREAKPOINTS.sm ? '90vw' : '80vw'}
        style={{ minWidth: '400px', height: '80vh', overflowY: 'scroll' }}
      >
        <CodeOfConductText />
      </Modal>
      <S.ReferencesWrapper>
        <S.Wrapper>
          <img
            src={EUFlag}
            width="auto"
            height={30}
            alt="EU flag"
            loading="lazy"
            style={{ marginLeft: (isCollapsed || !isSignedIn) && !smallScreen ? '60px' : '0px' }}
          />
          <P2>
            {t('common.footer', {
              defaultValue:
                'This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No: 959157. This website represents the views of the ACROSS project only.',
            })}
          </P2>
        </S.Wrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: mobileOnly ? 'column' : 'row',
          }}
        >
          <Button
            size="small"
            type="link"
            className={`policy ${location?.pathname !== '/login' && location?.pathname !== '/' ? 'policyLeft' : ''}`}
            onClick={() => setPolicyOpened(true)}
          >
            {t('common.policy', { defaultValue: 'Privacy Policy' })}
          </Button>
          <Button
            size="small"
            type="link"
            className={`policy ${location?.pathname !== '/login' && location?.pathname !== '/' ? 'policyLeft' : ''}`}
            onClick={() => setConductOpened(true)}
          >
            {t('common.codeOfConduct', { defaultValue: 'Code of Conduct' })}
          </Button>
        </div>
      </S.ReferencesWrapper>
      {largerScreen && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};
