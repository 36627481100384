import { BREAKPOINTS, LAYOUT } from '@app/styles/themes/constants';
import { media } from '@app/styles/themes/constants';
import { Layout } from 'antd';
import styled from 'styled-components/macro';

export const Header = styled(Layout.Header)`
  line-height: 1.5;
  border-bottom: var(--border-light-color);
  box-shadow: var(--box-shadow);
  position: relative;

  @media only screen and (${media.md}) {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
  }

  &.loggedOut {
    height: 6rem;

    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      height: 4rem;
      padding: 5px;
      min-width: 330px;
    }
  }
`;
