import type { AuthClientTokens } from '@react-keycloak/core';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';
import deDe from 'antd/lib/locale/de_DE';
import elGR from 'antd/lib/locale/el_GR';
import enUS from 'antd/lib/locale/en_US';
import lvLV from 'antd/lib/locale/lv_LV';
import React from 'react';
import { Toaster } from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';
import 'typeface-lato';
import 'typeface-montserrat';
import { setSession } from './api/config/http.api';
import { AppRouter } from './components/router/AppRouter';
// import { notificationController } from './controllers/notificationController';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { useLanguage } from './hooks/useLanguage';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { usePWA } from './hooks/usePWA';
import { themeObject } from './styles/themes/themeVariables';
import { KeycloakInitOptions } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './Keycloack';
import GlobalStyle from './styles/GlobalStyle';
import { notificationDarkColorsTheme } from './styles/themes/dark/darkTheme';
import { notificationLightColorsTheme } from './styles/themes/light/lightTheme';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const keycloakProviderInitConfig: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  };
  const tokenLogger = (tokens: AuthClientTokens) => {
    const { token } = tokens;
    if (token) setSession(token);
  };

  const getLocale = (language: string): Locale => {
    // until we implement UIC.setLanguage or similar,
    // we have to do the following steps manually
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).UIConnector) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const UIC = (window as any).UIConnector;
      let userLang = language;

      if (language == 'gr') userLang = 'el';

      UIC.setOptions({ userLang });
      if (UIC.m.protocolClient.connectionState === 'ready') {
        UIC.stop();
      }
      //UIC.start(); // used to (re-)start the uic when language has changed
    }

    switch (language) {
      case 'en':
        return enUS;
      case 'de':
        return deDe;
      case 'lv':
        return lvLV;
      case 'gr':
        return elGR;
      default:
        return enUS;
    }
  };

  const backgroundColorToaster =
    theme === 'dark' ? notificationDarkColorsTheme.backgroundColor : notificationLightColorsTheme.backgroundColor;
  const colorToaster = theme === 'dark' ? notificationDarkColorsTheme.color : notificationLightColorsTheme.color;

  useAutoNightMode();

  useThemeWatcher();

  usePWA();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <Toaster
        reverseOrder={false}
        toastOptions={{
          className: '',
          style: {
            backgroundColor: backgroundColorToaster,
            color: colorToaster,
          },
        }}
      />
      <ConfigProvider locale={getLocale(language)}>
        <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger} initOptions={keycloakProviderInitConfig}>
          <AppRouter />
        </ReactKeycloakProvider>
      </ConfigProvider>
    </>
  );
};

export default App;
