import styled from 'styled-components/macro';
import { Tag as AntTabs } from 'antd';
import { BREAKPOINTS } from '@app/styles/themes/constants';

export const UserStoriesWrapper = styled.div`
  .ant-row {
    padding: 1rem 0;
    border-top: 1.5px solid var(--lightgrey);
  }

  .ant-row:first-child {
    border-top-width: 0;
  }
`;

export const MainWrapper = styled.div`
  height: 100%;
  padding: 1.5rem 2.5rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 1.5rem;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content {
    position: relative;
    width: 100%;
    background: var(--background-color);
    padding: 2rem;
    border-radius: 7px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .ant-tabs-content-holder {
    box-shadow: var(--box-shadow);
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--text-secondary-color) !important;
    background: var(--sider-background-color) !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--text-secondary-color) !important;
  }
`;

export const Header = styled.div`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

export const Title = styled.span`
  padding: 0px 20px 0 0;
`;

export const Tag = styled.span`
  display: flex;
  align-items: flex-end;

  .flex-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  }
`;

export const ListWrapper = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  padding-bottom: 2rem;
`;

export const Unauthorized = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: var(--primary-color);
  height: 100%;
`;

export const ApproveStatusTitle = styled.div`
  font-size: 1rem;
  color: var(--primary-color);
  padding: 2rem 0;
`;

export const Tabs = styled(AntTabs)`
  background: pink;
`;
