import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IConsentService, IExternalConsentService } from '@app/interfaces/interfaces';
import { postRemoteConsentApi } from '@app/api/http/remoteConsent.api';
import { refactorConsentServiceJourneys } from './journeysSlice';

interface ConsentSlice {
  loadingConsentGranting: boolean;
  consentGrantingService: IConsentService | null;
}

const initialState: ConsentSlice = {
  loadingConsentGranting: false,
  consentGrantingService: null,
};

export const clearConsentService = createAction('consent/clearConsent');

export const postRemoteConsent = createAsyncThunk(
  'consent/postRemoteConsent',
  async (externalConsentServices: IExternalConsentService[], { dispatch, rejectWithValue }) =>
    postRemoteConsentApi(externalConsentServices)
      .then((res) => {
        const result = (res.data || []).pop();
        const { serviceId }: { serviceId: string } = result;
        dispatch(refactorConsentServiceJourneys({ serviceId }));
        return result;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postRemoteConsent.pending, (state) => {
      state.loadingConsentGranting = true;
    });
    builder.addCase(postRemoteConsent.fulfilled, (state, action) => {
      state.loadingConsentGranting = false;
      state.consentGrantingService = action.payload;
    });
    builder.addCase(postRemoteConsent.rejected, (state) => {
      state.loadingConsentGranting = false;
    });
    builder.addCase(clearConsentService, (state) => {
      state.loadingConsentGranting = false;
      state.consentGrantingService = null;
    });
  },
});

export default consentSlice.reducer;
