import { executeServiceApi, serviceInfoApi } from '@app/api/http/serviceInfo.api';
import { IServiceInfo, IServiceStatus } from '@app/interfaces/interfaces';
import { createAction, createAsyncThunk, createSelector, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { RootState } from '../store';
import { refactorStateServiceJourney } from './journeysSlice';

interface ServiceInfoState {
  serviceInfo: IServiceInfo[];
  loadingPost: string | null;
  loading: string | null;
  servicePostSuccess: string | null;
}

const initialState: ServiceInfoState = {
  serviceInfo: [],
  loading: null,
  loadingPost: null,
  servicePostSuccess: null,
};

const selectServiceInfoItems = (state: RootState) => ({
  serviceInfo: state.serviceInfo.serviceInfo,
  loading: state.serviceInfo.loading,
});

export const selectServiceInfo = createSelector(
  [selectServiceInfoItems, (_, serviceId: string) => serviceId],
  ({ serviceInfo, loading }, serviceId) => ({
    serviceInfo: serviceInfo.find((x) => x.serviceId === serviceId) || null,
    loading,
  }),
);

export const setServicePostSuccess = createAction<PrepareAction<string>>(
  'serviceInfo/setServicePostSuccess',
  (status: string) => {
    return {
      payload: status,
    };
  },
);

export const setLoading = createAction<PrepareAction<string>>('serviceInfo/setLoading', (serviceId: string) => {
  return {
    payload: serviceId,
  };
});

export const executeServiceLoading = createAction<PrepareAction<string>>(
  'serviceInfo/postServiceApplyLoading',
  (key: string) => {
    return {
      payload: key,
    };
  },
);

export const fetchServiceInfo = createAsyncThunk(
  'serviceInfo/fetchServiceInfo',
  async (serviceId: string, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(serviceId));
    return serviceInfoApi(serviceId)
      .then((res) => res.data)
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

export const executeService = createAsyncThunk(
  'serviceInfo/executeService',
  async (
    data: { workflowInstanceId: string; phaseId: string; actionId: string; serviceId: string; form: FormData },
    { dispatch, rejectWithValue },
  ) => {
    const { workflowInstanceId, phaseId, actionId, serviceId, form } = data;
    dispatch(executeServiceLoading(`${workflowInstanceId}-${phaseId}-${actionId}-${serviceId}`));
    return executeServiceApi(workflowInstanceId, phaseId, actionId, serviceId, form)
      .then((res) => {
        const stepStatus: IServiceStatus = res.data.stepStatus;
        dispatch(setServicePostSuccess(`${workflowInstanceId}-${phaseId}-${actionId}-${serviceId}`));
        dispatch(setServicePostSuccess(null));
        if (stepStatus) {
          dispatch(
            refactorStateServiceJourney({ workflowInstanceId, phaseId, actionId, serviceId, status: stepStatus }),
          );
        }
        return res.data;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

const serviceInfoSlice = createSlice({
  name: 'serviceInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setServicePostSuccess, (state, action) => {
      state.servicePostSuccess = action.payload;
    });
    builder.addCase(executeServiceLoading, (state, action) => {
      state.loadingPost = action.payload;
    });
    builder.addCase(executeService.fulfilled, (state) => {
      state.loadingPost = null;
    });
    builder.addCase(executeService.rejected, (state) => {
      state.loadingPost = null;
    });
    builder.addCase(setLoading, (state, action) => {
      state.loading = action.payload;
    });
    builder.addCase(fetchServiceInfo.fulfilled, (state, action) => {
      state.serviceInfo = uniqBy([...state.serviceInfo, action.payload], 'serviceId');
      state.loading = null;
    });
    builder.addCase(fetchServiceInfo.rejected, (state) => {
      state.loading = null;
    });
  },
});

export default serviceInfoSlice.reducer;
