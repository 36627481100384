import { IJourney } from '@app/interfaces/interfaces';

export const SUPPORTED_LANGUAGES = ['de', 'en', 'lv', 'gr', 'el']; //NOTE: "el" and "gr" are both supported for Greek language

export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#ffffff',
  black: '#000000',
  green: '#006600',
  orange: '#ffb155',
  gray: '#808080',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  lightorange: '#FFD580',
  lightred: '#FF7276',
  lightskyblue: '#87cefa',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff0000',
  cyan: '#20B2AA',
  lightcyan: '#48D1CC',
  yellow: '#fff000',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.5rem',
    paddingHorizontal: '0.25rem',
    headerHeight: '4rem',
    headerPadding: '0.5rem',
  },
  desktop: {
    paddingVertical: '0.5rem',
    paddingHorizontal: '1.5rem',
    headerHeight: '4rem',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};

export const SIDER_SIZES = {
  siderButton: '52px',
};

export const fakeJourney: IJourney = {
  workflowInstanceId: 'fakeJourney',
  workflowContent: [],
  servicesWithoutConsent: [],
  createdAt: 1694763818552,
  name: '',
  userId: 'fakeUser',
  workflowId: 'fakeWorkflow',
  workflowType: 'STUDY',
  countryOrigin: 'DEU-GERMANY',
  countryDestination: 'GRC-GREECE',
  workflowStatus: 'running',
  description: [
    { locale: 'en', value: 'Description of User Journey' },
    { locale: 'el', value: 'Περιγραφή της Διαδρομής' },
    { locale: 'de', value: 'Beschreibung der Reise' },
    { locale: 'lv', value: 'Ceļojuma apraksts' },
  ],
};
