import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import { MediaQueryAllQueryable, MediaQueryMatchers, useMediaQuery } from 'react-responsive';

interface ResponsiveReturnValues {
  isMobile: boolean;
  isSmallMobile: boolean;
  isMediumMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isBigScreen: boolean;
  mobileOnly: boolean;
  tabletOnly: boolean;
  desktopOnly: boolean;
  smallScreen: boolean;
  extraSmallScreen: boolean;
  largeScreen: boolean;
  largerScreen: boolean;
  largeScreen_2: boolean;
  useMediaQuery: (
    settings: Partial<MediaQueryAllQueryable & { query?: string | undefined }>,
    device?: MediaQueryMatchers,
    callback?: (matches: boolean) => void,
  ) => boolean;
}

export const useResponsive = (): ResponsiveReturnValues => {
  const isMobile = useMediaQuery({ query: media.xs });
  const isSmallMobile = useMediaQuery({ query: media.sm });
  const isTablet = useMediaQuery({ query: media.md });
  const isDesktop = useMediaQuery({ query: media.xl });
  const isBigScreen = useMediaQuery({ query: media.xxl });

  const isMediumMobile = useMediaQuery({ query: `(max-width: 500px )` });

  const mobileOnly = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.md - 0.02}px)`,
  });

  const tabletOnly = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.xl - 0.02}px)`,
  });

  const desktopOnly = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.xl}px) and (max-width: ${BREAKPOINTS.xxl - 0.02}px)`,
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.sm - 0.02}px)`,
  });

  const extraSmallScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.xs - 0.02}px)`,
  });

  const largeScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.lg - 0.02}px)`,
  });

  const largerScreen = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.xl - 0.02}px)`,
  });

  const largeScreen_2 = useMediaQuery({
    query: `(max-width: 1180px)`,
  });

  return {
    isMobile,
    isSmallMobile,
    isMediumMobile,
    isTablet,
    isDesktop,
    isBigScreen,
    mobileOnly,
    tabletOnly,
    desktopOnly,
    smallScreen,
    extraSmallScreen,
    largeScreen,
    largerScreen,
    largeScreen_2,
    useMediaQuery,
  };
};
