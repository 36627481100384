import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo-light.png';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed }) => {
  const { tabletOnly } = useResponsive();

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="">
        <img src={logo} alt="Across Logo" width={'auto'} height={tabletOnly ? (isSiderCollapsed ? 20 : 37) : 37} />
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
