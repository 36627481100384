import { PersonModel } from '@app/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

const BASE_SUB_PATH = 'userProfile';

export const getUserProfileApi = (): Promise<AxiosResponse> => httpApi.get<PersonModel>(`${BASE_SUB_PATH}`);

export const postUserProfileApi = (data: PersonModel): Promise<AxiosResponse> =>
  httpApi.post<PersonModel>(`${BASE_SUB_PATH}`, data);
