import React, { useState } from 'react';
import { capitalize } from 'lodash';
import * as S from './UserStoriesAdmin.style';
import { Row, Col, Space, Radio, Modal } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { countryLocale } from '@app/utils/utils';
import { Avatar } from '@app/components/common/Avatar/Avatar';
import { IUserStories } from '@app/interfaces/interfaces';
import { Dates } from '@app/constants/Dates';
import profileImage from 'assets/images/profile-user.png';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { sendFeedbackApprovalStatus } from '@app/store/slices/feedbackSlice';
import { useTranslation } from 'react-i18next';
import i18n from '@app/i18n';
import { CaretRightOutlined } from '@ant-design/icons';
import { Alert } from '@app/components/common/Alert/Alert';

export const UserStoriesAdmin: React.FC<IUserStories> = ({
  origin,
  purpose,
  destination,
  text,
  userInitials,
  userFirstName,
  userSurname,
  userEmail,
  createdAt,
  approve,
  _id,
}) => {
  const { t } = useTranslation();
  const [approveStatus, setApproveStatus] = useState('');
  const [isModalAppStatusOpen, setIsModalAppStatusOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = (e: RadioChangeEvent) => {
    setIsModalAppStatusOpen(true);
    setApproveStatus(e.target.value);
  };

  const handleOk = () => {
    dispatch(
      sendFeedbackApprovalStatus({
        origin: origin || '',
        purpose: purpose || '',
        destination: destination || '',
        text: text,
        userInitials: userInitials ?? '',
        userFirstName: userFirstName ?? '',
        userSurname: userSurname ?? '',
        userEmail: userEmail ?? '',
        approve: approveStatus,
        _id: _id ?? '',
      }),
    );
    setIsModalAppStatusOpen(false);
  };

  const handleCancel = () => {
    setIsModalAppStatusOpen(false);
    setApproveStatus('');
  };

  const capitalizeCountry = (countryName: string) => {
    if (countryName.indexOf('-') === 3) {
      return i18n.language !== 'en'
        ? capitalize(t(countryLocale(countryName.substring(4))))
        : capitalize(countryName.substring(4));
    } else {
      return i18n.language !== 'en' ? capitalize(t(countryLocale(countryName))) : capitalize(countryName);
    }
  };

  const localePurpose = (purpose: string) => {
    if (purpose === 'study') {
      return t('common.studies', { defaultValue: 'Studies' });
    } else if (purpose === 'work') {
      return t('common.work', { defaultValue: 'Work' });
    } else {
      return '';
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <S.Story>
            <Space.Compact block>
              <div className="wrapper-infoBottom">
                <S.Wrapper>
                  <S.AuthorAvatarWrapper>
                    {userInitials && userInitials.length > 0 ? (
                      <Avatar shape="circle" style={{ background: '#0150C2' }}>
                        {userInitials}
                      </Avatar>
                    ) : (
                      <Avatar shape="circle" src={profileImage} alt={'Image'} />
                    )}
                  </S.AuthorAvatarWrapper>
                  <S.Feedback>
                    {origin && purpose && destination && (
                      <S.JourneyDetails>
                        <span className="admin-journey__details">
                          {capitalizeCountry(origin)} <CaretRightOutlined /> {capitalizeCountry(destination)}{' '}
                          {t('journey.for', { defaultValue: 'for' })} {localePurpose(purpose.toLowerCase())}
                        </span>
                        <br />
                      </S.JourneyDetails>
                    )}
                    <Alert message={<S.Text>&#10077; {`${text}`} &#10078;</S.Text>} type="info"></Alert>
                  </S.Feedback>
                </S.Wrapper>

                <S.UserData>
                  <div>
                    {' '}
                    {t('adminMenu.userName', { defaultValue: 'User Name:' })} {userFirstName}&nbsp;
                    {userSurname ? userSurname : userFirstName ? '' : '-'}
                  </div>{' '}
                  <span>
                    {t('adminMenu.userEmail', { defaultValue: 'User Email:' })}{' '}
                    <a href={`mailto:${userEmail}`}>{userEmail ?? '-'}</a>{' '}
                  </span>
                </S.UserData>

                <S.InfoBottomWrapper>
                  <S.DateText>{Dates.getDate(createdAt).format('DD-MM-YYYY')}</S.DateText>
                </S.InfoBottomWrapper>
              </div>
              <S.ApproveWrapper>
                {approve === 'pending' ? (
                  <Radio.Group onChange={handleChange} value={approveStatus}>
                    <Radio value={'approved'}>
                      <S.ApproveText>
                        <AiOutlineCheck color="green" />
                      </S.ApproveText>
                      {t('adminMenu.approve', { defaultValue: 'Approve' })}
                    </Radio>
                    <Radio value={'disapproved'}>
                      <S.ApproveText>
                        <AiOutlineClose color="red" />
                      </S.ApproveText>
                      {t('adminMenu.reject', { defaultValue: 'Reject' })}
                    </Radio>
                  </Radio.Group>
                ) : (
                  <>
                    <S.ApproveText>
                      {approve === 'approved' ? <AiOutlineCheck color="green" /> : <AiOutlineClose color="red" />}
                    </S.ApproveText>
                    {approve === 'approved' ? (
                      <span> {t('adminMenu.approved', { defaultValue: 'Approved' })}</span>
                    ) : (
                      <span> {t('adminMenu.rejected', { defaultValue: 'Rejected' })}</span>
                    )}
                  </>
                )}
              </S.ApproveWrapper>
            </Space.Compact>
          </S.Story>
        </Col>
      </Row>
      <Modal open={isModalAppStatusOpen} onOk={handleOk} onCancel={handleCancel} afterClose={handleCancel}>
        <p>
          {t('adminMenu.storiesModal_1', { defaultValue: 'Are you sure you want to' })}
          {approveStatus === 'approved' ? (
            <span style={{ color: 'green' }}> {t('adminMenu.approve', { defaultValue: 'Approve' })}</span>
          ) : (
            <span style={{ color: 'red' }}> {t('adminMenu.reject', { defaultValue: 'Reject' })}</span>
          )}{' '}
          {t('adminMenu.storiesModal_2', { defaultValue: 'this user story' })}
        </p>
      </Modal>
    </>
  );
};
