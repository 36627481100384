import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { IHistory } from '@app/interfaces/interfaces';
import { getHistoryApi, getStatisticsInfoApi } from '@app/api/http/historyService.api';
import { AppDispatch, RootState } from '../store';
import { isEmpty } from 'lodash';

const PAGE_SIZE = 5;
const INITIAL_PAGE = 1;

interface FeedbackSlice {
  loading: boolean;
  history: { [key: string]: IHistory[] };
  totalElements: number;
  page: number;
  pageSize: number;
  engineStats: { numUsersStartedJourney: number; numStartedJourneys: number };
}

const initialState: FeedbackSlice = {
  loading: false,
  history: {},
  totalElements: 0,
  page: INITIAL_PAGE,
  pageSize: PAGE_SIZE,
  engineStats: { numUsersStartedJourney: 0, numStartedJourneys: 0 },
};

export const getHistory = createAsyncThunk(
  'history/getHistory',
  async (
    {
      page,
      pageSize,
      workflowInstanceId,
      phaseId,
      actionId,
      serviceId,
    }: {
      page: number;
      pageSize: number;
      workflowInstanceId: string;
      phaseId: string;
      actionId: string;
      serviceId?: string;
    },
    { rejectWithValue },
  ) =>
    getHistoryApi(page, pageSize, workflowInstanceId, phaseId, actionId, serviceId)
      .then((res) => res.data)
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const fetchEngineStatistics = createAsyncThunk(
  'workflows/fetchEngineStatistics',
  async (_, { rejectWithValue }) =>
    getStatisticsInfoApi()
      .then((res) => res.data)
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

const selectHistory = (state: RootState) => ({
  history: state.history.history,
  loading: state.history.loading,
  totalElements: state.history.totalElements,
});

export const selectHistoryInfo = createSelector(
  [selectHistory, (_, page: number) => page],
  ({ history, loading, totalElements }, page) => ({
    history: history[page.toString()] || null,
    loading,
    totalElements,
  }),
);

export const fetchHistory =
  ({
    page,
    pageSize,
    workflowInstanceId,
    phaseId,
    actionId,
    serviceId,
  }: {
    page: number;
    pageSize: number;
    workflowInstanceId: string;
    phaseId: string;
    actionId: string;
    serviceId?: string;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const state: RootState = getState();
    const history = state.history.history[page.toString()];
    if (isEmpty(history)) {
      dispatch(loadingHistory());
      dispatch(getHistory({ page, pageSize, workflowInstanceId, phaseId, actionId, serviceId }));
    }
  };

export const clearHistory = createAction('history/clearHistory');
export const loadingHistory = createAction('history/loadingHistory');

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadingHistory, (state) => {
      state.loading = true;
    });
    builder.addCase(getHistory.fulfilled, (state, { payload }) => {
      const { content, totalElements, pageNo } = payload;
      state.history[pageNo.toString()] = content;
      state.totalElements = totalElements;
      state.loading = false;
    });
    builder.addCase(getHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(clearHistory, (state) => {
      state.history = initialState.history;
      state.loading = initialState.loading;
      state.totalElements = initialState.totalElements;
    });
    builder.addCase(fetchEngineStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEngineStatistics.fulfilled, (state, { payload }) => {
      const { numUsersStartedJourney, numStartedJourneys } = payload;
      state.engineStats = { numStartedJourneys: numStartedJourneys, numUsersStartedJourney: numUsersStartedJourney };
      state.loading = false;
    });
    builder.addCase(fetchEngineStatistics.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default historySlice.reducer;
