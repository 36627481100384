import { shadeColor } from '@app/utils/utils';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartColor1: '#339CFD',
  chartColor2: '#dc88f5',
  chartColor3: '#FFB765',
  chartColor4: '#306955',
  chartColor5: '#ff3d71',
};

export const darkColorsTheme: ITheme = {
  primary: '#339CFD',
  primary1: '#7568f6',
  primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)',
  light: '#696969',
  secondary: '#0072DD',
  error: '#FF5252',
  warning: '#FFB765',
  success: '#57D682',
  background: '#25284B',
  secondaryBackground: '#1c2137',
  secondaryBackgroundSelected: shadeColor('#1c2137', -5),
  tertiaryBackground: '#8D8D8D',
  loginSecondaryBackground: '#223056',
  secondaryIcon: '#A7A7A7',
  additionalBackground: '#1D203E',
  collapseBackground: '#1D203E',
  timelineBackground: '#f5f5f5',
  siderBackground: '#121430',
  spinnerBase: '#339CFD',
  scroll: '#797C9A',
  border: '#ffffff',
  textMain: '#ffffff',
  textLight: '#9A9B9F',
  textSuperLight: '#444',
  textSecondary: '#ffffff',
  textDark: '#404040',
  textSiderPrimary: '#339CFD',
  textSiderSecondary: '#ffffff',
  textTransparencyDashboard: '#ffffff',
  subText: '#a9a9a9',
  textBlueish: '#85B7D3',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: 'none',
  boxShadowHover: 'none',
  borderLight: `1px solid ${BASE_COLORS.gray}`,
  dashboardMapBackground: '#25284b',
  dashboardMapCircleColor: '#ACAEC1',
  dashboardMapControlDisabledBackground: '#7e7e7e',
  mainBackground: '#1e2142',
  heading: BASE_COLORS.white,
  borderBase: '#a9a9a9',
  disable: '#7e7e7e',
  disabledBg: '#1c2137',
  disabledServiceBg: '#1c2137',
  disabledPlaceholderColor: '#989898',
  layoutBodyBg: '#1e2142',
  layoutHeaderBg: '#1e2142',
  layoutSiderBg: '#1e2142',
  inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
  itemHoverBg: '#1c2137',
  backgroundColorBase: '#1c2137',
  avatarBg: '#1c2137',
  alertTextColor: '#000',
  breadcrumb: '#a9a9a9',
  icon: '#a9a9a9',
  iconHover: '#ffffff',
  userStoriesBackground: '#96B6CD',
  statsBackground: '#232369',
  statsText: '#231868',
  statsNumber: '#150481',
  statsShadow: 'transparent',
  loginSection1Background: '#121430',
  loginFooterBackground: '#020011',
  phaseHeaderBackground: '#23425b',
  actionHeaderBackground: '#305B7D',
  metadataHeaderBackground: '#305B7D',
  metadataTableBorder: '#47657F',
  modalHeaderBackground: '#23425b',
  modalCloseIcon: '#a9a9a9',
  historyIconColor: '#ffb155',
  languageHoverColor: '#1259b0',
  countryCodeBackground: 'rgb(11, 138, 238)',
  finishedJourneyBackground: '#444',
  stepsWaitText: 'rgba(255, 255, 255, 0.6)',
  lightWhiteDarkBlack: '#000000',
  lightBlackDarkWhite: '#FFFFFF',
  bubbleText: '#1e2142',
  skippedColor: '#B4B4B4',
  partnerLogosBackground: '#c5d3e0',
  partnerLogosHover: '#DBECFD',
  carouselBtnBackground: '#596888',
  greenText: '#62CE62',
  newJourneyText: '#4BB3FB',
  ...chartColors,
};

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
};

export const notificationDarkColorsTheme = {
  backgroundColor: BASE_COLORS.black,
  color: BASE_COLORS.white,
};
