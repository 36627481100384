import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import Avatar from 'react-avatar';
import { Popover } from '@app/components/common/Popover/Popover';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { isNil } from 'lodash';
import { UserModel } from '@app/interfaces/interfaces';
import { H5 } from '@app/components/common/typography/H5/H5';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const [open, setOpen] = useState(false);
  const handleOpenChange = () => setOpen(!open);
  const user = useAppSelector((state) => state.userInfo.user);

  const getContent = (user: UserModel) => {
    const { data, role } = user;
    const { familyName, givenName, preferredUsername } = data;

    const userName =
      preferredUsername && preferredUsername.trim() !== ''
        ? preferredUsername.trim()
        : (givenName || '') + ' ' + (familyName || '');

    return (
      <div id="profile_button">
        <Popover
          id="profile_dropdown"
          open={open}
          onOpenChange={handleOpenChange}
          showArrow={false}
          placement="bottom"
          content={<ProfileOverlay $handleOpenChange={handleOpenChange} />}
          trigger={['click']}
          overlayClassName="popover__container"
        >
          <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle" justify="center">
            <S.Column>
              <Avatar name={userName} size="40" round maxInitials={2} />
            </S.Column>
            {isTablet && (
              <Col style={{ maxHeight: '50px' }}>
                <H5>{`${userName}`}</H5>
                <S.H6Text>{role}</S.H6Text>
              </Col>
            )}
          </S.ProfileDropdownHeader>
        </Popover>
      </div>
    );
  };

  return !isNil(user) ? getContent(user) : null;
};
