import React, { useEffect, useRef } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setSiderWidth } from '@app/store/slices/themeSlice';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { largerScreen } = useResponsive();
  const targetRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const toggleSider = () => setCollapsed(!isCollapsed);

  useEffect(() => {
    if (isCollapsed) {
      dispatch(setSiderWidth(280));
    } else {
      dispatch(setSiderWidth(0));
    }
  }, [dispatch, isCollapsed]);

  return (
    <>
      <S.Sider
        ref={targetRef}
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={isCollapsed ? 0 : 280}
        collapsible={true}
        width={280}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {largerScreen && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
