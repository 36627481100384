import * as S from './PrivacyPolicyText.styles';
import { Table } from '@app/components/common/Table/Table';
import Column from 'antd/lib/table/Column';
import logo from 'assets/logo-dark.png';
import React from 'react';

const PrivacyPolicyText: React.FC = () => {
  const operatorData: { column1: string; column2: string; key: string }[] = [
    {
      column1: 'Name of platform operator',
      column2: 'Athens Technology Centre (ATC)',
      key: 't1r1',
    },
    {
      column1: 'Address',
      column2: 'Rizareiou 10, Athens 152 33, Greece',
      key: 't1r2',
    },
    {
      column1: 'Company number',
      column2: 'EL094360380',
      key: 't1r3',
    },
  ];

  const dataCollection: { personalData: string; reason: string; legalBasis: string; key: string }[] = [
    {
      personalData:
        'Basic identification information (username, (email) address, phone number, et cetera) you provide us while registering to the platform and other information you decide to provide freely to us (by filling in specific forms on the platform). <br/><br/> Most of your personal identity data and your sensitive information (for example university application documents, documents for job application, et cetera) will be stored in your personal wallet, or by the external electronic identity service provider that you’ve chosen to use outside of this platform, and therefore not directly on the ACROSS Platform.<br/><br/> Some personal data that is required by the public administration/ third party service provider, but which is not part of your personal wallet or your eID, will be collected through forms on the platform. We will make sure that the data collected will be limited to what is strictly necessary for the provision of the cross-border service.',
      reason:
        'Sharing (if you consent to it) of your personal data with public administrations and third party service providers (university, banks, health and liability insurers, real-estate agencies, et cetera) to enable cross-border services in the field of citizens mobility. <br/> <br/> <br/> To facilitate at your request the exercising of data subject rights on the ACROSS platform (for example obtaining a copy of your personal data, requesting the service provider to delete your personal data, et cetera). ',
      legalBasis: 'Your specific and informed consent as an end user of the ACROSS platform.',
      key: 't2r1',
    },
    {
      personalData: 'Any information you publish on the public website in comments or experiences. ',
      reason: 'To share your user experience on the website and to improve the user experience of the ACROSS platform.',
      legalBasis: 'Your specific and informed consent.',
      key: 't2r2',
    },
    {
      personalData:
        'Metadata such as data on your activity, profile and/or behaviour on the platform. <br/><br/>In this case your IP address, date and time of access, browser used, operating system, etcetera may be processed.',
      reason:
        'Enabling the use of the ACROSS platform and to make the Platform user-friendly and optimize its functioning. ',
      legalBasis: 'Our legitimate interest in ensuring the proper functioning of our platform. ',
      key: 't2r3',
    },
    {
      personalData:
        'Voice recordings and any information you provide to the ACROSS Virtual Assistant (either by using the voice assistant or by using the chat bot assistant). <br/><br/> Please note that when using the ACROSS virtual Assistant your voice will be recorded including any characteristics (emotions, accents, etc.). This voice recording will only happen when you explicitly consent to it.',
      reason:
        'In order to give you a user-friendly experience of the platform and to assist you through the different functionalities on the platform.',
      legalBasis:
        'Your specific and informed consent.<br/><br/>Your explicit consent for the recording of your voice via your audio devices on your PC, laptop, tablet or smartphone, will be asked when first accessing the ACROSS website. Additionally, you will need to switch on the microphone each time you want to use the voice assistant. ',
      key: 't2r4',
    },
    {
      personalData:
        'Where applicable: URLs or other technical reference techniques that can connect you to the status of your individual application procedure,  which will be linked to your user profile.',
      reason:
        'To enable you to consult your application procedure status and (if you choose to do so), to take additional steps in your application process  in a user-friendly way. ',
      legalBasis: 'Our legitimate interest in ensuring a user-friendly platform.',
      key: 't2r5',
    },
  ];

  const additionalDataCollection: { addPersonalData: string; addReason: string; addLegalBasis: string; key: string }[] =
    [
      {
        addPersonalData: 'Above-mentioned personal data.',
        addReason:
          'To comply with our legal obligations or to comply with any reasonable request from competent police authorities, judicial authorities, government institutions or bodies, including competent data protection authorities.',
        addLegalBasis: 'Our legal obligation.',
        key: 't3r1',
      },
      {
        addPersonalData: 'Above-mentioned personal data.',
        addReason: 'To prevent, detect and combat fraud or other illegal or unauthorized activities.',
        addLegalBasis: 'Our legal obligation.',
        key: 't3r2',
      },
      {
        addPersonalData: 'Above-mentioned personal data.',
        addReason:
          'To ensure the security of the platform, for example to prevent unauthorized access to the platform. ',
        addLegalBasis: 'Our legitimate interest in ensuring a safe and secure platform. ',
        key: 't3r3',
      },
      {
        addPersonalData: 'Above-mentioned personal data.',
        addReason: 'To defend ourselves in legal proceedings.',
        addLegalBasis: 'Our legitimate interest in using your personal data in these proceedings.',
        key: 't3r4',
      },
    ];

  return (
    <S.TextWrapper>
      <>
        <S.ImgContainer>
          <img src={logo} alt="Across" width="170px" style={{ marginBottom: '20px' }}></img>
        </S.ImgContainer>
        <p>
          We attach great importance to the security and confidentiality of your personal data. This privacy policy
          informs you about the processing of your personal data on the ACROSS platform.{' '}
        </p>
        <h4>1. WHEN DOES THIS PRIVACY POLICY APPLY? </h4>
        <p>
          1.1. You are of course free to use the ACROSS platform or not. If you do decide to us it, we will need to
          process and collect some of your personal data. In such instances, this privacy policy will apply. If you
          disagree with any part of the privacy policy, please refrain from using the platform.
        </p>
        <p>
          1.2. This privacy policy may be amended as set forth in Article 8. We will inform you of any significant
          changes that come up.
        </p>
        <h4>2. WHO ARE WE?</h4>
        <p>2.1. “We” in this privacy policy refers to the ACROSS Platform operator. </p>
        <span>
          <Table bordered dataSource={operatorData} className="operatorTable" rowKey="key">
            <Column title="column1" dataIndex="column1" key="column1" />
            <Column title="column1" dataIndex="column2" key="column2" />
          </Table>
        </span>
        <br />
        <p>
          2.2. We are responsible for the collection and use of your personal data in the manner explained in this
          privacy policy. If you have any questions about this, please contact the platform’s data protection officer by
          e-mail <a href="mailto: hans.graux@timelex.eu">(hans.graux@timelex.eu)</a>.
        </p>
        <p>
          2.3. Please note that this privacy policy can only apply to the platform itself. Any service providers that
          receive your personal data through the ACROSS platform are responsible for their own processing of your
          personal data, and must be regarded as independent data controllers, who will apply their own privacy
          policies. These are outside of our control. Therefore, we also recommend you to consult the privacy policies
          of the service providers directly via their website prior to making your data available to them.
        </p>
        <h4>3. WHICH PERSONAL DATA DO WE PROCESS AND WHY?</h4>
        <p>
          We will only process your personal data for a specific purpose and to the extent permitted by law. We further
          explain below in which cases we collect and use your personal data. If we do not receive your personal data
          directly from you, we will also inform you of this below.{' '}
        </p>
        <h4>3.1. WHEN YOU USE THE ACROSS PLATFORM </h4>
        <p> When you use the ACROSS platform, we will collect and process the following information: </p>
        <span>
          <Table bordered dataSource={dataCollection} className="dataCollectionTable" rowKey="key">
            <Column
              title="What personal data?"
              dataIndex="personalData"
              key="personalData"
              render={(text) => <div dangerouslySetInnerHTML={{ __html: text }} />}
              className="align-top"
            />
            <Column
              title="Why?"
              dataIndex="reason"
              key="reason"
              render={(text) => <div dangerouslySetInnerHTML={{ __html: text }} />}
              className="align-top"
            />
            <Column
              title="Legal Basis"
              dataIndex="legalBasis"
              key="legalBasis"
              render={(text) => <div dangerouslySetInnerHTML={{ __html: text }} />}
              className="align-top"
            />
          </Table>
        </span>
        <br />
        <p>
          {' '}
          The processing will be limited to these purposes. We will not use your data for tracking, profiling or data
          selling/trading, surveillance or direct marketing purposes, unless you explicitly consent to this.
        </p>
        <h4>3.2. ADDITIONAL USE OF YOUR DATA</h4>
        <p>
          3.2.1. For all personal data that we collect in the above circumstances, we may also need to process your
          personal data in the following cases.
        </p>
        <span>
          <Table bordered dataSource={additionalDataCollection} className="additionalDataCollectionTable" rowKey="key">
            <Column title="What personal data?" dataIndex="addPersonalData" key="addPersonalData" />
            <Column title="Why?" dataIndex="addReason" key="addReason" />
            <Column title="Legal Basis" dataIndex="addLegalBasis" key="addLegalBasis" />
          </Table>
        </span>
        <br />
        <h4>4. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h4>
        <p>
          4.1. In principle, we do not share your personal data with anyone other than the persons who work for us, as
          well as with the service providers you explicitly gave your consent for. Anyone who has access to your
          personal data will always be bound by strict legal or contractual obligations to keep your personal data safe
          and confidential. This means that only the following categories of recipients will receive your personal data:
        </p>
        <p className="list">
          ● You; <br />
          ● Our employees and suppliers; <br />
          ● The service provider connected to the platform -to the extent you consented to share your data with them;
          and <br />● Government or judicial authorities to the extent that we are obliged to share your personal data
          with them (e.g. tax authorities, police or judicial authorities).
        </p>
        <p>
          4.2. We do not transfer your personal data outside the European Economic Area (EEA) (the European Economic
          Area consists of the EU, Liechtenstein, Norway and Iceland) in principle, except if you explicitly request us
          to do so, i.e. when you consent to data sharing with a service provider outside the EEA or if you would move
          to a country outside the EEA. If a transfer were to take place, we will take sufficient safeguards to protect
          your personal data during the transfer, either on the basis of an adequacy decision of the European Commission
          or if not yet in place, by entering into an agreement based on standard data protection clauses approved by
          the European Commission.
        </p>
        <h4>5. HOW LONG DO WE KEEP YOUR PERSONAL DATA?</h4>
        <p>
          5.1. You can use the user interface on the platform to define the retention period for which we can share your
          personal data with any of the service providers you consented to. At any moment, you can decide to modify this
          pre-set retention period by using the user interface.{' '}
        </p>
        <p>
          5.2. We will also stop processing your personal data when you decide to withdraw your consent for the
          processing. Note that we cannot technically oblige the service provider that has received your personal data
          based on your consent and has stored a copy of your personal data, to delete that copy. You can request the
          service provider to delete the copy of your personal data, by using the e-mail link that will allow you to
          directly communicate with the DPO of the service provider. Your request will be further handled according to
          the procedure of the service provider.{' '}
        </p>
        <p>
          5.3. As a general rule, we will delete or de-identify your personal data when it is no longer needed for the
          purposes described above or when the retention period, as explained in this Article 5, has expired. This
          includes the termination of the ACROSS platform’s operation, of which we would inform you in advance. However,
          we cannot delete your personal data if there is a legal or regulatory obligation or a court or administrative
          order preventing us from doing so.
        </p>
        <h4>6. HOW DO WE KEEP YOUR PERSONAL DATA SECURE?</h4>
        <p>
          6.1. The security and confidentiality of the personal data we process is very important to us. That is why we
          have taken technical and organizational measures to ensure that all personal data processed is kept secure.
          These measures include:
        </p>{' '}
        <p className="list">
          ● Access controls: your personal data on the ACROSS Platform will only be accessible to third party service
          providers you consented to. The access to the ACROSS Platform will be controlled using a login and a secure
          password; <br />● Transfer controls: your personal data will be protected against unlawful interception
          through effective encryption (SSL encryption);
          <br /> ● Logging and audit trails: the exchanges of personal data on the ACROSS platform is logged in a way
          that allows all the interactions to be identified and examined. The logs only comprise metadata.{' '}
        </p>
        <h4>7. YOUR RIGHTS REGARDING YOUR PERSONAL DATA</h4>
        <p>
          7.1. The personal data the ACROSS platform stores about you, e.g. user name, password, etc. can be accessed,
          changed, corrected and deleted by you in the profile setting. You therefore do not need to send us a formal
          request for exercising these rights (although you of course are free to do so). If you want to exercise your
          rights against the service providers, you can use the e-mail link that is provided on the Platform, and which
          will allow you to directly contact the DPO of the Service Provider. The rights you can exercise against the
          service providers on the platform will be described in more detail below.{' '}
        </p>
        <p>
          7.2. You can use the e-mail link to request from the service provider to give you access to your personal
          data. Additionally, you may also ask the service provider to provide you a copy of your personal data. Please
          be aware that the service provider may require some additional information to verify your identity. The reason
          for this is to prevent a data breach, by giving unlawful access to your personal data.{' '}
        </p>
        <p>
          7.3. You have the right to request to request the service provider to correct your personal data if you can
          demonstrate that the personal data the service provider process about you is inaccurate, incomplete, or out of
          date. You can exercise your request by using the e-mail link that will allow you to communicate with the DPO
          of the Service Provider. We highly recommend that you to indicate in your e-mail the context in which the
          service provider uses your personal information (e.g., to respond to a request), so that they can review your
          request quickly and accurately.
        </p>
        <p>
          7.4. If you have granted your consent to collect, use and share your personal data, you have the right to
          withdraw this consent at any time. The withdrawal of your consent will stop the data sharing with third
          parties in the ACROSS platform immediately. Note that, in this case, we will not require the third party
          service providers to delete your personal data which they had accessed before the withdrawal of your consent.
          You can use e-mail link within the ACROSS platform to communicate with the DPO of the service provider to
          delete your stored personal data by that service provider.{' '}
        </p>{' '}
        <p>
          7.5. You may ask the service provider via the e-mail link on the ACROSS Platform to erase your personal data
          if these personal data are no longer necessary for the purposes for which they received them through the
          ACROSS platform, if their collection was unlawful or if you have successfully exercised your right to withdraw
          your consent or your right to object to the processing of your personal data by the service provider. When
          either of these circumstances applies, the service provider will be obliged to erase your personal data
          immediately, unless legal obligations or administrative or judicial orders prohibit them from deleting your
          personal data.{' '}
        </p>
        <p>
          7.6. You may ask the service provider to restrict the processing of your personal data using the e-mail link:
        </p>
        <p className="list">
          ● during the time needed to review your request for correction of your personal data;
          <br />● during the time needed to review your objection to the processing of your personal data;
          <br /> ● when such processing was unlawful, but you prefer a restriction to erasure; and
          <br /> ● when the service provider no longer needs your personal data, but you need them for the
          establishment, exercise, or defense of any legal action.
        </p>
        <p>
          7.7. If we have collected your personal data on the basis of your consent, you have the right to obtain a copy
          from us in a structured, widely used and machine-readable format.{' '}
        </p>
        <p>
          7.8. If you wish to exercise any of these rights, you can use the e-mail link on the ACROSS Platform.. By
          using the e-mail link, you can directly send your request to the DPO of the of the service provider. The
          service provider will further handle your request in accordance with their own internal procedures; please
          note that we cannot and will not intervene in this interaction, since we cannot monitor your communications
          with the service provider under applicable law.{' '}
        </p>
        <p>
          7.9. If you have a complaint about the processing of your personal data by us, you can always contact us at{' '}
          <a
            href="mailto:
          hans.graux@timelex.eu"
          >
            hans.graux@timelex.eu
          </a>
          . If you are not satisfied with our response, you may lodge a complaint with the competent data protection
          authority, you can find a list of the competent data protection authorities in the different European Member
          States and their contact information on this website (
          <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" target="_blank" rel="noreferrer">
            https://edpb.europa.eu/about-edpb/about-edpb/members_en
          </a>
          ).
        </p>
        <h4>8. CHANGES TO THIS PRIVACY POLICY </h4>{' '}
        <p>
          8.1. We can change this privacy policy on our own initiative at any time. If material changes to this privacy
          policy may affect the processing of your personal data, we will communicate these changes to you in a way that
          we normally communicate with you (e.g. via e-mail).
        </p>
        <p>
          8.2. We invite you to read the latest version of this privacy policy on our website{' '}
          <a
            href="https://citizen-webapp-citizen-application-dev.k8s.across-h2020.eu/login"
            target="_blank"
            rel="noreferrer"
          >
            https://citizen-webapp-citizen-application-dev.k8s.across-h2020.eu/login
          </a>
          .
        </p>
        <h4>9. DO YOU HAVE ANY QUESTIONS?</h4>
        <p>
          9.1. Should you have any further questions about the processing of your personal data, please do not hesitate
          to contact our data protection officer. You can contact our data protection officer by e-mail:{' '}
          <a href="mailto:hans.graux@timelex.eu">hans.graux@timelex.eu. </a>
        </p>
      </>
    </S.TextWrapper>
  );
};

export default PrivacyPolicyText;
