import styled from 'styled-components/macro';

export const TextWrapper = styled.div`
  color: var(--black);
  p,
  span {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-main-color);
  }
  h4 {
    font-weight: bolder !important;
    font-size: 15px !important;
  }

  .ant-pagination {
    display: none;
  }

  .list {
    margin-left: 15px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;
