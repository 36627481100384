import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Badge } from '@app/components/common/Badge/Badge';
import { NotificationsOverlay } from '@app/components/header/components/notificationsDropdown/NotificationsOverlay/NotificationsOverlay';
import { Notification } from '@app/api/notifications.api';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import { useSelector } from 'react-redux';
import { fetchJourney, selectJourneyInfo } from '@app/store/slices/journeysSlice';
import { useParams } from 'react-router-dom';
import { IServicesWithoutConsent } from '@app/interfaces/interfaces';
import { emitCloseModals } from '@app/store/slices/actionsSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { deleteNotification } from '@app/store/slices/notificationsSlice';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from '@app/utils/utils';

export const NotificationsDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(true);
  const { workflowInstanceId } = useParams();
  const dispatch = useAppDispatch();
  const { journey } = useSelector((state) => selectJourneyInfo(state, workflowInstanceId || ''));
  const { journeys } = useAppSelector((state) => state.journeys);
  const { notifications: stateNotifications } = useAppSelector((state) => state.notifications);
  const closeModals = useAppSelector((state) => state.actions.closeModals);
  const { servicesWithoutConsent }: { servicesWithoutConsent: IServicesWithoutConsent[] } = journey || {
    servicesWithoutConsent: [],
  };

  const { t } = useTranslation();

  const togglePopover = () => {
    dispatch(emitCloseModals());
    setTimeout(() => setOpened(!isOpened));
  };
  useEffect(() => {
    if (closeModals && isOpened) setOpened(false);
  }, [closeModals, isOpened]);

  const onRemoveNotification = (id?: string) => (id ? dispatch(deleteNotification(id)) : {});

  const journeyNotifications: Notification[] = servicesWithoutConsent.map((x) => ({
    description: x.serviceName,
    href: `https://transparency-dashboard-td-dev.k8s.across-h2020.eu/admin/consents-management?lang=${getCurrentLanguage()}`,
    place: 'common.transparency',
    type: 'consent',
  }));

  const JourneyFilteredNotifications = !consent
    ? journeyNotifications.filter((x) => x.type !== 'consent')
    : journeyNotifications;

  const stateFilteredNotifications: Notification[] = stateNotifications.map((x) => ({
    id: x.id,
    createdAt: x.createdAt,
    description: `WorkflowInstanceId: ${x.workflowInstanceId}, phaseId: ${x.phaseId}, actionId: ${x.actionId}, serviceId: ${x.serviceId} -> <b>${x.status}</b>`,
    type: 'provider',
    serviceInfo: {
      status: x.status,
      workflowInstanceId: x.workflowInstanceId,
      phaseId: x.phaseId,
      actionId: x.actionId,
      serviceId: x.serviceId,
      userId: x.userId,
      id: x.id,
      createdAt: x.createdAt,
      url: x.url,
    },
  }));
  const notifications = [...JourneyFilteredNotifications, ...stateFilteredNotifications];

  useEffect(() => {
    if (isOpened && notifications.length > 0) {
      const providerNot = notifications.filter((not) => not.type === 'provider');
      const workflowIds: string[] = [];
      providerNot.forEach((pn) => {
        if (
          pn?.serviceInfo?.workflowInstanceId &&
          workflowIds.indexOf(pn.serviceInfo.workflowInstanceId) === -1 &&
          !journeys.some((j) => j.workflowInstanceId === pn.serviceInfo?.workflowInstanceId)
        ) {
          workflowIds.push(pn.serviceInfo.workflowInstanceId);
        }
      });

      workflowIds.forEach((id) => {
        dispatch(
          fetchJourney({
            workflowInstanceId: id,
            reloadUjoPage: false,
            cb: () => {
              null;
            },
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  const calculateNotifications = () => {
    const consentNotifications = notifications.some((n) => n.type === 'consent') ? 1 : 0;
    const otherNotifications = notifications.filter((not) => not.type !== 'consent').length;

    return otherNotifications + consentNotifications;
  };

  return (
    <Popover
      open={isOpened}
      showArrow={false}
      trigger="manual"
      content={
        <NotificationsOverlay
          setConsent={setConsent}
          onRemoveNotification={onRemoveNotification}
          notifications={notifications}
          onClosePopover={togglePopover}
        />
      }
      overlayClassName="popover__notifications"
    >
      <HeaderActionWrapper>
        <Tooltip title={t('notifications.title', { defaultValue: 'Notifications' })}>
          <Button
            onClick={togglePopover}
            style={{ borderWidth: '0' }}
            type={isOpened ? 'ghost' : 'text'}
            icon={
              <Badge status="error" count={calculateNotifications()}>
                <BellOutlined />
              </Badge>
            }
          />
        </Tooltip>
      </HeaderActionWrapper>
    </Popover>
  );
};
