import { shadeColor } from '@app/utils/utils';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartColor1: '#01509A',
  chartColor2: '#35A0DC',
  chartColor3: '#FFB155',
  chartColor4: '#31A652',
  chartColor5: '#FF5252',
};

export const lightColorsTheme: ITheme = {
  primary: '#01509A',
  primary1: '#f5f5f5',
  primaryGradient: 'linear-gradient(211.49deg, #006CCF 15.89%, #00509A 48.97%)',
  light: '#C5D3E0',
  secondary: '#0085FF',
  error: '#FF5252',
  warning: '#FFB155',
  success: '#30AF5B',
  background: BASE_COLORS.white,
  secondaryBackground: '#F8FBFF',
  secondaryBackgroundSelected: shadeColor('#F8FBFF', -5),
  tertiaryBackground: '#D9D9D9',
  loginSecondaryBackground: '#f9fbfc',
  secondaryIcon: '#A1A1A1',
  additionalBackground: '#ffffff',
  collapseBackground: 'rgb(0, 108, 207)',
  timelineBackground: '#F8FBFF',
  siderBackground: 'rgb(7, 89, 133)',
  spinnerBase: '#f42f25',
  scroll: '#c5d3e0',
  border: '#cce1f4',
  textMain: '#404040',
  textLight: '#71768B',
  textSuperLight: '#BEC0C6',
  textSecondary: BASE_COLORS.white,
  textDark: '#404040',
  textSiderPrimary: BASE_COLORS.white,
  textSiderSecondary: BASE_COLORS.lightgrey,
  textTransparencyDashboard: '#a3a3a3',
  subText: 'rgba(0, 0, 0, 0.45)',
  textBlueish: '#075985',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
  boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  borderLight: '1px solid rgba(0, 0, 0, 0.06)',
  dashboardMapBackground: '#EAF5FF',
  dashboardMapCircleColor: '#9BC2E7',
  dashboardMapControlDisabledBackground: '#c5d3e0',
  mainBackground: '#F1F5F9',
  heading: '#13264d',
  borderBase: '#bec0c6',
  disable: 'rgba(0, 0, 0, 0.5)',
  disabledBg: '#c5d3e0',
  disabledServiceBg: '#e0e0e0',
  disabledPlaceholderColor: 'rgba(0, 0, 0, 0.5)',
  layoutBodyBg: '#f8fbff',
  layoutHeaderBg: '#ffffff',
  layoutSiderBg: '#ffffff',
  inputPlaceholder: '#404040',
  itemHoverBg: '#f5f5f5',
  backgroundColorBase: '#F5F5F5',
  avatarBg: '#ccc',
  alertTextColor: BASE_COLORS.white,
  breadcrumb: 'rgba(0, 0, 0, 0.45)',
  icon: '#a9a9a9',
  iconHover: 'rgba(0, 0, 0, 0.75)',
  userStoriesBackground: '#93BFD7',
  statsBackground: '#FFFFFF',
  statsText: '#3b81b3',
  statsNumber: 'rgb(1, 80, 154)',
  statsShadow: 'rgba(215, 224, 229, 0.5)',
  loginSection1Background: 'rgb(7, 89, 133)',
  loginFooterBackground: '#082749',
  phaseHeaderBackground: '#80A8CD',
  actionHeaderBackground: '#d9e4ef',
  metadataHeaderBackground: '#d9e4ef',
  metadataTableBorder: 'rgba(0, 0, 0, 0.06)',
  modalHeaderBackground: '#80A8CD',
  modalCloseIcon: 'rgba(0,0,0,0.45)',
  historyIconColor: '#D14900',
  languageHoverColor: '#f1f5f9',
  countryCodeBackground: 'rgb(186, 230, 253)',
  finishedJourneyBackground: '#d4d6dc',
  stepsWaitText: 'rgba(64, 64, 64, 0.6)',
  lightWhiteDarkBlack: '#ffffff',
  lightBlackDarkWhite: '#000000',
  bubbleText: '#075985',
  skippedColor: '#4E4E4E',
  partnerLogosBackground: '#FFFFFF',
  partnerLogosHover: '#FBFBFB',
  carouselBtnBackground: '#c5d3e0',
  greenText: '#006600',
  newJourneyText: 'rgb(1, 80, 154)',
  ...chartColors,
};

export const notificationLightColorsTheme = {
  backgroundColor: BASE_COLORS.white,
  color: BASE_COLORS.black,
};
