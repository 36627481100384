import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeOutlined, RightOutlined } from '@ant-design/icons';
import * as S from './Breadcrumb.styles';

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const { workflowInstanceId } = useParams();

  const breadcrumbNameMap: Record<string, string[]> = {
    '/app': [t('breadcrumbs.dashboard', { defaultValue: 'Home' })],
    '/app/journeys': [t('breadcrumbs.journeys', { defaultValue: 'My Journeys' })],
    [`/app/journeys/${workflowInstanceId}`]: [t('breadcrumbs.journey', { defaultValue: 'My Journey' })],
    '/app/favorites': [t('breadcrumbs.favorite', { defaultValue: 'Favorite Services' })],
    '/app/admin': [t('breadcrumbs.admin', { defaultValue: 'Admin' })],
  };

  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return url === '/app' ? (
      <Breadcrumb.Item key={url}>
        <Link to={url}>
          <HomeOutlined />
          {breadcrumbNameMap[url]}
        </Link>
      </Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <S.BreadcrumbSection>
      <Breadcrumb separator=<RightOutlined />>{extraBreadcrumbItems}</Breadcrumb>
    </S.BreadcrumbSection>
  );
};

export default Breadcrumbs;
