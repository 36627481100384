import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components/macro';
import { HeaderActionWrapper } from '../../../Header.styles';
import { H6 } from '@app/components/common/typography/H6/H6';
import { Col } from 'antd';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;
  margin: 0 !important;
  padding: 0 10px;

  @media only screen and (${media.md}) {
    border-radius: 50px;
  }

  .sb-avatar__text {
    background-color: var(--ant-primary-color) !important;
    color: var(--light-white-dark-black-color) !important;
  }

  H5 {
    max-height: 25px !important;
  }
`;

export const Column = styled(Col)`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and (${media.md}) {
    color: var(--text-main-color);
  }
`;

export const H6Text = styled(H6)`
  margin: 0 !important;
  font-size: 12px !important;
`;
