import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface ActionState {
  collapsed: number | null;
  scroll: boolean;
  closeModals: boolean;
}

const initialState: ActionState = {
  collapsed: null,
  scroll: false,
  closeModals: false,
};

export const setCollapsedOpened = createAsyncThunk('actions/setCollapsedOpened', (index: number) =>
  Promise.resolve(index),
);

export const emitScroll = createAsyncThunk('actions/emitScroll', () => Promise.resolve());
export const emitCloseModals = createAsyncThunk('actions/emitCloseModals', () => Promise.resolve());

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCollapsedOpened.pending, (state) => {
      state.collapsed = 0;
    });
    builder.addCase(setCollapsedOpened.fulfilled, (state, { payload }) => {
      state.collapsed = payload;
    });
    builder.addCase(emitScroll.pending, (state) => {
      state.scroll = true;
    });
    builder.addCase(emitScroll.fulfilled, (state) => {
      state.scroll = false;
    });
    builder.addCase(emitCloseModals.pending, (state) => {
      state.closeModals = true;
    });
    builder.addCase(emitCloseModals.fulfilled, (state) => {
      state.closeModals = false;
    });
  },
});

export default actionsSlice.reducer;
