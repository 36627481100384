import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from './RequireAuth';

const ProtectedLayout = (): JSX.Element => (
  <RequireAuth>
    <MainLayout hasSidebar />
  </RequireAuth>
);

export default ProtectedLayout;
