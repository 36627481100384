import userInfoSlice from '@app/store/slices/userInfoSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import feedbackReducer from '@app/store/slices/feedbackSlice';
import journeysSlice from './journeysSlice';
import personSlice from './personSlice';
import workflowsSlice from './workflowsSlice';
import serviceInfoSlice from './serviceInfoSlice';
import timeCostMetadataSlice from './timeCostMetadataSlice';
import consentSlice from './consentSlice';
import favoriteSlice from './favoriteSlice';
import historySlice from './historySlice';
import actionsSlice from './actionsSlice';
import pwaReducer from './pwaSlice';
import notificationsSlice from './notificationsSlice';

export default {
  pwa: pwaReducer,
  userInfo: userInfoSlice,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  feedback: feedbackReducer,
  journeys: journeysSlice,
  person: personSlice,
  workflows: workflowsSlice,
  serviceInfo: serviceInfoSlice,
  timeCostMetadata: timeCostMetadataSlice,
  consent: consentSlice,
  favorite: favoriteSlice,
  history: historySlice,
  actions: actionsSlice,
  notifications: notificationsSlice,
};
