import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

const BASE_SUB_PATH = 'workflowExecutionManagement';

export const serviceInfoApi = (serviceId: string): Promise<AxiosResponse> =>
  httpApi.get<AxiosResponse>(`${BASE_SUB_PATH}/getServiceInfo?serviceId=${serviceId}`);

export const executeServiceApi = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  serviceId: string,
  data: FormData,
): Promise<AxiosResponse> =>
  httpApi.post<AxiosResponse>(
    `${BASE_SUB_PATH}/executeService?workflowInstanceId=${workflowInstanceId}&phaseId=${phaseId}&actionId=${actionId}&serviceId=${serviceId}`,
    data,
    {
      transformRequest: (formData) => formData,
    },
  );
