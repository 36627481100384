import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

const BASE_SUB_PATH = 'serviceCatalogueManagement';

export const getCostServiceApi = (serviceId: string): Promise<AxiosResponse> =>
  httpApi.get(`${BASE_SUB_PATH}/cost?serviceId=${encodeURI(serviceId)}`);

export const getTimeServiceApi = (serviceId: string): Promise<AxiosResponse> =>
  httpApi.get(`${BASE_SUB_PATH}/time?serviceId=${encodeURI(serviceId)}`);

export const getMetadataServiceApi = (serviceId: string): Promise<AxiosResponse> =>
  httpApi.get(`${BASE_SUB_PATH}/metadata?serviceId=${encodeURI(serviceId)}`);

export const getServiceCountApi = (): Promise<AxiosResponse> => httpApi.get('serviceCatalogue/count');

export const getServicesApi = (): Promise<AxiosResponse> => httpApi.get('serviceCatalogue');
