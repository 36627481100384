import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Empty, Space } from 'antd';
import { Notification as NotificationType } from 'api/notifications.api';
import * as S from './NotificationsOverlay.styles';
import { H4 } from '@app/components/common/typography/H4/H4';
import { FaRegEnvelopeOpen } from 'react-icons/fa';
import { CloseOutlined } from '@ant-design/icons';
import { filter, find } from 'lodash';
import { P2 } from '@app/components/common/typography/P2/P2';
import { Button } from '@app/components/common/buttons/Button/Button';
import { H6 } from '@app/components/common/typography/H6/H6';
import { Dates } from '@app/constants/Dates';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { INotification } from '@app/interfaces/interfaces';
import { allowNoHtmlChars, getCurrentLanguage, getValueLocale } from '@app/utils/utils';
import sanitizeHtml from 'sanitize-html';

interface NotificationsOverlayProps {
  notifications: NotificationType[];
  setConsent: (val: boolean) => void;
  onRemoveNotification: (index?: string) => void;
  onClosePopover: () => void;
}

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({
  notifications,
  setConsent,
  onRemoveNotification,
  onClosePopover,
  ...props
}) => {
  const { t } = useTranslation();
  const providerList = useMemo(() => filter(notifications || [], ({ type }) => type === 'provider'), [notifications]);
  const consentListSample = find(notifications, ({ type }) => type === 'consent');
  const consentListLength = filter(notifications, ({ type }) => type === 'consent').length;
  const consentList = useMemo(() => (consentListSample ? [consentListSample] : []), [consentListSample]);
  const notificationsList = useMemo(() => [...consentList, ...providerList], [consentList, providerList]);
  const { journeys } = useAppSelector((state) => state.journeys);

  const onClose = useCallback(() => setConsent(false), [setConsent]);
  const openTransparencyDashboard = () =>
    window.open(
      `https://transparency-dashboard-td-dev.k8s.across-h2020.eu/admin/available-services?lang=${getCurrentLanguage()}`,
      '_self',
    );

  const getServiceDetails = (serviceInfo: INotification | undefined) => {
    const journey = journeys.find((jrn) => jrn.workflowInstanceId === serviceInfo?.workflowInstanceId);
    const phase = journey?.workflowContent.find((phase) => phase.phase_id === serviceInfo?.phaseId);
    const action = phase?.actions.find((act) => act.action_id === serviceInfo?.actionId);
    const service = action?.touchpoints.find((tp) => tp.service_id === serviceInfo?.serviceId);

    return {
      journey: journey?.name,
      phase: phase?.name,
      action: action?.name,
      serviceName: service?.name ? getValueLocale(service.name) : '',
    };
  };

  const noticesList = useMemo(
    () =>
      notificationsList.map((x) => (
        <S.NotificationConsent key={x.type === 'consent' ? x.description : x.id}>
          <Space>
            <S.Icon $danger={x.type === 'consent'} size={5} />
            {x.type === 'consent' ? (
              <Space
                onClick={openTransparencyDashboard}
                className="notifications__consent-content"
                direction="vertical"
                align="start"
              >
                <H6>{t('notifications.consent.title', { defaultValue: 'Pending consent requests' })}</H6>
                <P2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('notifications.consent.text', {
                        consentListLength,
                      }),
                    }}
                  ></div>
                </P2>
              </Space>
            ) : (
              <Space className="notifications__provider-content" direction="vertical" align="start">
                <H6>
                  <Space align="baseline" className="provider_header">
                    <span>
                      {t('notifications.provider.service', { defaultValue: 'The status of Service' })} {" '"}
                      {sanitizeHtml(getServiceDetails(x.serviceInfo)?.serviceName, allowNoHtmlChars)}
                      {"' "}
                      {t('notifications.provider.hasBeenUpdated', { defaultValue: 'has been updated' })}
                    </span>
                  </Space>
                </H6>
                <P2>
                  {t('notifications.provider.body', { defaultValue: 'The new status of your application form is:' })}{' '}
                  <span
                    className={
                      x?.serviceInfo?.status &&
                      (x.serviceInfo.status === 'rejected' ? 'statusRejected' : 'statusFinished')
                    }
                  >
                    {t(`service.status.${x.serviceInfo?.status}`)}
                  </span>
                  {x.serviceInfo?.url && (
                    <>
                      <P2>
                        {t('notifications.provider.outputText', {
                          defaultValue: 'You can view the Service response by clicking on this',
                        })}{' '}
                        <a href={x.serviceInfo.url} target="_blank" rel="noreferrer" className="outputUrl">
                          {t('notifications.provider.link', { defaultValue: 'link' })}
                        </a>
                      </P2>
                    </>
                  )}
                  {x.createdAt && <P2 className="date">{Dates.getDate(x.createdAt).format('lll')}</P2>}
                </P2>
              </Space>
            )}
          </Space>
          {x.type === 'consent' ? (
            <Button onClick={onClose} size="small" type="text" shape="circle" icon={<CloseOutlined />} />
          ) : (
            <Button
              onClick={() => onRemoveNotification(x.id)}
              size="small"
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
            />
          )}
        </S.NotificationConsent>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consentListLength, notificationsList, onClose, onRemoveNotification, t],
  );

  return (
    <S.NoticesOverlayMenu {...props}>
      <S.HeaderMenu>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '5px' }}>
          <FaRegEnvelopeOpen />
          <H4 className="title">{t('notifications.title', { defaultValue: 'Notifications' })}</H4>
        </div>
        <Button
          id="closeNotifications"
          onClick={() => onClosePopover()}
          size="small"
          type="text"
          shape="circle"
          icon={<CloseOutlined />}
        />
      </S.HeaderMenu>
      <S.MenuRow gutter={[20, 20]}>
        <Col span={24}>
          {notificationsList.length > 0 ? ( //TODO:add loading
            <Space className="list__item" direction="vertical" size={10} split={<S.SplitDivider />}>
              {noticesList}
            </Space>
          ) : (
            <Empty
              className="empty__content"
              description={t('notifications.noNotifications', { defaultValue: 'No notifications' })}
            />
          )}
        </Col>
      </S.MenuRow>
    </S.NoticesOverlayMenu>
  );
};
