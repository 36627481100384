import styled from 'styled-components/macro';
import { Typography } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, FONT_FAMILY, BREAKPOINTS } from '@app/styles/themes/constants';

export const Story = styled.div`
  .ant-space-compact {
    display: flex;
    justify-content: space-between;
  }

  .ant-space-compact {
    @media only screen and (max-width: ${BREAKPOINTS.md}px) {
      flex-direction: column;
      gap: 0.2rem;
    }

    .wrapper-infoBottom {
      width: calc(100% - 12rem);

      @media only screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
`;

export const ApproveWrapper = styled.div`
  width: 10rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;

  .ant-radio-wrapper {
    min-width: 155px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 1rem;
    width: 100%;
  }
  label,
  span {
    font-size: 1rem;
    padding-right: 0.2rem;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media only screen and (min-width: 400px) and (max-width: ${BREAKPOINTS.md}px) {
      flex-direction: row;
    }
  }
`;

export const ApproveText = styled.span`
  vertical-align: middle;
  line-height: 0;

  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: flex-start;
  }
`;

export const AuthorAvatarWrapper = styled.div`
  // border-radius: 50%;
  // border: 2px solid var(--text-secondary-color);
  // border: 2px solid var(--primary-color);
  // height: 37px;
  // width: 36px;
`;

export const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-alert-info {
    background-color: var(--login-secondary-background-color);
  }
`;

export const JourneyDetails = styled(Typography.Text)`
  font-size: ${FONT_SIZE.md};
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .admin-journey__details {
    flex-wrap: nowrap !important;
    min-height: 30px;
  }

  @media only screen and (max-width: 400px) {
    flex-direction: column;
    margin-bottom: -20px;
    .countryFlags {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: -15px;
  }

  img {
    font-size: 25px;
    margin-bottom: 10px;
  }

  svg {
    font-size: 1rem;
  }
`;

export const Text = styled(Typography.Text)`
  line-height: 1.4;
  font-size: 1.1rem;
  font-weight: 400;
  font-style: inherit;
  padding-right: 1rem;
`;

export const InfoBottomWrapper = styled.div`
  text-align: start;
  margin-right: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  font-weight: ${FONT_WEIGHT.light};
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: ${FONT_WEIGHT.light};
  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
    gap: 3px;
  }
`;

export const DateText = styled(Text)`
  font-family: ${FONT_FAMILY.main};
  font-size: ${FONT_SIZE.xxs};
  @media only screen and (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 12px;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
`;
