import styled from 'styled-components/macro';

export const BreadcrumbSection = styled.div`
  padding-bottom: 20px;

  .anticon-home {
    padding-right: 0.3rem;
    padding-top: 0px;
    font-size: 12px;
    vertical-align: baseline;
  }

  .ant-breadcrumb a:hover,
  .ant-breadcrumb li:last-child,
  .ant-breadcrumb li:last-child a {
    color: var(--primary-color) !important;
  }
`;
