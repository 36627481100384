import React, { useEffect } from 'react';

import * as S from './PreLoginMenu.styles';
import { capitalize, handleScrollTo } from '@app/utils/utils';
import { useTranslation } from 'react-i18next';
import Overlay from '@app/components/common/Overlay';

interface PreLoginMenuProps {
  className?: string;
  setIsOpenHeaderMenu: (isOpenHeaderMenu: boolean) => void;
  isOpenHeaderMenu: boolean;
}

export const PreLoginMenu: React.FC<PreLoginMenuProps> = ({ className, setIsOpenHeaderMenu, isOpenHeaderMenu }) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const toggleHeaderMenu = () => setIsOpenHeaderMenu(!isOpenHeaderMenu);

  const menuItems = [
    { scrollId: 'WhatWeOffer', title: capitalize(t('infoSection_1.title', { defaultValue: 'What We Offer' })) },
    { scrollId: 'Discover', title: t('infoSection_2.sideMenuText', { defaultValue: 'Discover' }) },
    { scrollId: 'UserStories', title: t('infoSection_3.title', { defaultValue: 'What People Say About Us' }) },
    { scrollId: 'Partners', title: t('infoSection_4.title', { defaultValue: 'Who We Are' }) },
    { scrollId: 'Video', title: t('infoSection_5.title', { defaultValue: 'Learn More' }) },
  ];

  useEffect(() => {
    if (setIsOpenHeaderMenu) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const checkIfClickedOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setIsOpenHeaderMenu(false);
        }
      };
      document.addEventListener('click', checkIfClickedOutside);
      return () => {
        document.removeEventListener('click', checkIfClickedOutside);
      };
    }
  }, [setIsOpenHeaderMenu]);

  return (
    <>
      <S.SectionWrapper
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <S.HeaderMenu className={className}>
          {menuItems.map((item) => (
            <S.HeaderMenuItem
              className={className}
              key={item.scrollId}
              onClick={() => {
                handleScrollTo(item.scrollId);
                if (setIsOpenHeaderMenu) {
                  setIsOpenHeaderMenu(false);
                }
              }}
            >
              <a>{item.title}</a>
            </S.HeaderMenuItem>
          ))}
        </S.HeaderMenu>
      </S.SectionWrapper>
      {
        <div className="prelogin__overlay">
          <Overlay onClick={toggleHeaderMenu} show={isOpenHeaderMenu} />
        </div>
      }
    </>
  );
};

export default PreLoginMenu;
