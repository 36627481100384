import styled, { css } from 'styled-components/macro';
import { Col, Collapse, Menu } from 'antd';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { BORDER_RADIUS, BREAKPOINTS, LAYOUT, media } from '@app/styles/themes/constants';
import { Button } from '../common/buttons/Button/Button.styles';

export const DropdownMenu = styled(Menu)`
  line-height: 1.5715;

  border-radius: ${BORDER_RADIUS};

  &.ant-dropdown-menu {
    box-shadow: var(--box-shadow);
  }

  .ant-menu-item {
    margin: 0 !important;
    height: auto;
  }
`;

export const ToggleSideMenu = styled.div`
  padding-left: 3px;

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    margin-left: -11px;
    padding-left: 0;
  }
`;

export const BurgerButton = styled(Button)`
  border: none;
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    path {
      stroke: var(--text-main-color);
    }
  }
`;

export const WrapperLogoHMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINTS.xl}px) and (min-width: ${BREAKPOINTS.sm}px) {
    gap: 2rem;
  }
`;

export const WrapperHeaderSocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0rem;

  @media only screen and (${media.xl}) {
    gap: 2rem;
  }
  @media only screen and (max-width: 385px) {
    display: none;
  }
`;

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and (${media.md}) {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const DropdownCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and (${media.md}) {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;

export const BurgerColHeaderMenu = styled(Col)`
  z-index: 999;
  display: flex;
  width: 50px;
  justify-content: center;
`;

export const MobileBurgerHeaderMenu = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: white;
    `};
`;

export const SearchColumn = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

export const PreLoginMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
`;

export const PreLoginMenuWrapperBurger = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  flex-direction: column;

  .prelogin__overlay {
    div {
      top: 0;
      left: 266px;
      z-index: 2;
    }
  }
`;

export const ProfileColumn = styled(Col)`
  max-height: 50px;
`;

export const ProfileRow = styled.div`
  display: flex;
  align-items: center;

  .ant-row {
    align-items: center;
  }

  &.withGap {
    .ant-row {
      @media screen and (max-width: 767px) and (min-width: 400px) {
        gap: 0.3rem;
      }
    }
  }
`;

export const DivWrapper = styled.div`
  cursor: pointer;
  font-size: 1.625rem;
  margin-top: 0;
  margin-left: 1rem;
  margin-right: 10px;

  Button {
    border-radius: 50px;
    height: 38px;
    margin-top: 0;
    background-color: var(--ant-primary-color);
    color: var(--light-white-dark-black-color);
    border: 2px solid var(--ant-primary-color);
    opacity: 1;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--ant-primary-color);
      color: var(--light-white-dark-black-color);
      border: 2px solid var(--ant-primary-color);
      opacity: 0.9;
      transition: 0.4s all ease-in-out;
    }

    &.smallLoginBtn {
      height: 34px;
    }

    @media only screen and (max-width: 385px) {
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
    margin-top: 0px;
    margin-left: -5px;
  }
`;

export const LanguageCol = styled(Col)`
  @media only screen and (max-width: 385px) {
    left: 25px;
  }
  .ant-dropdown-menu {
    min-width: 155px !important;
  }

  .countryCode {
    margin-left: 5px;
    background-color: var(--country-code-background);
    color: #000000;
    border-radius: 50px;
    width: 40px;
    min-height: 25px;
    max-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .countryShort {
    margin-left: unset;
  }

  .buttonShort {
    width: 40px;
    height: 25px;
    border-color: transparent !important;
    margin-top: 0px;
  }

  Button {
    border-radius: 50px;
    height: 38px;
    margin-top: 0;
    border-color: rgb(203 213 225);
    &:hover {
      background-color: var(--language-background-hover);
      border-color: rgb(203 213 225);
      color: var(--text-main-color);
    }
    &:focus {
      border-color: rgb(203 213 225);
      color: var(--text-main-color);
    }
  }
`;

export const WrapperHeaderPreLogin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding-right: 1rem;

  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
`;

export const HeaderItem = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  @media screen and (max-width: 400px) {
    gap: 0.3rem;
  }

  .socialMediaIcon {
    font-size: 2rem;
    color: var(--text-main-color);
    display: flex;
    font-size: 1.5rem;
  }
`;
