import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { INotification } from '@app/interfaces/interfaces';
import { useKeycloak } from '@react-keycloak/web';
import Pusher from 'pusher-js';
import { appendNotification, clearNotifications, getNotifications } from '@app/store/slices/notificationsSlice';
import { fetchJourneys } from '@app/store/slices/journeysSlice';

// Configure Pusher
const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
  cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
});

export const useNotifications = (): void => {
  const [userId, setUserId] = useState<string | null>();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.tokenParsed?.sub) setUserId(keycloak.tokenParsed?.sub);
    else setUserId(null);
  }, [dispatch, keycloak]);

  useEffect(() => {
    if (userId) {
      if (pusher.connection.state === 'disconnected') pusher.connect();
      const channel = pusher.subscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
      // Bind to an event
      channel.bind(`${userId}`, (data: INotification) => {
        dispatch(appendNotification(data));
        dispatch(fetchJourneys());
      });
      // Retrieve notifications
      setTimeout(() => dispatch(getNotifications()));
    } else if (pusher.connection.state === 'connected') {
      dispatch(clearNotifications());
      pusher.unsubscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
      pusher.disconnect();
    }
    // Clean up on component unmount
    return () => {
      if (pusher.connection.state === 'connected') {
        dispatch(clearNotifications());
        pusher.unsubscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
        pusher.disconnect();
      }
    };
  }, [dispatch, userId]);
};
