import { BREAKPOINTS } from '@app/styles/themes/constants';
import styled from 'styled-components/macro';

export const SectionWrapper = styled.section`
  display: flex;
`;

export const HeaderMenu = styled.div`
  display: flex;
  gap: 5px;

  &.burger__menu {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    padding: 8rem 2.52rem;
    background: var(--sider-background-color);
    color: white;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;

    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      padding: 6rem 2.52rem;
    }
  }
}
`;
export const HeaderMenuItem = styled.div`
  height: 64px;
  width: 100%;
  min-width: 185px;
  padding: 7px 2px !important;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.12) !important;
    border-radius: 6px;
    a {
      color: var(--text-sider-primary-color) !important;
    }
  }

  a {
    line-height: 50px;
    height: 64px;
    font-size: 1.06rem;
    text-decoration: none;
    position: relative;
  }

  &.burger__menu {
    a {
      color: var(--text-sider-secondary-color);
      font-size: 14px;
      margin: 0 5px;

      &:hover,
      &:active {
        color: var(--text-sider-primary-color);
      }
    }
  }
`;
