import { getWorkflowsApi, getWorkflowsForStatsApi, postUserWorkflowsApi } from '@app/api/http/workflows.api';
import i18n from '@app/i18n';
import { IJourney, IJourneyPost, IWorkflow } from '@app/interfaces/interfaces';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

const customError = 'common.modals.dashboard.customError';
const serverError = 'There is already one instance running for this Workflow';
export interface WorkflowsState {
  workflows: IWorkflow[];
  loading: boolean;
  userLoadingWorkflow: boolean;
  userNewWorkflow: IJourney | null;
  errorWorkflow: string;
}

const initialState: WorkflowsState = {
  workflows: [],
  loading: false,
  userLoadingWorkflow: false,
  userNewWorkflow: null,
  errorWorkflow: '',
};

export const emptyNewWorkflow = createAction('journeys/emptyNewWorkflow');
export const clearErrorWorkflow = createAction('journeys/errorWorkflow');

export const fetchWorkflows = createAsyncThunk('workflows/fetchWorkflows', async (_, { rejectWithValue }) =>
  getWorkflowsApi()
    .then((res) => res.data)
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const fetchWorkflowsForStats = createAsyncThunk(
  'workflows/fetchWorkflowsForStats',
  async (_, { rejectWithValue }) =>
    getWorkflowsForStatsApi()
      .then((res) => res.data)
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const createUserWorkflow = createAsyncThunk(
  'workflows/createUserWorkflow',
  async (data: IJourneyPost, { rejectWithValue }) =>
    postUserWorkflowsApi(data)
      .then((res) => {
        const journey = res.data;
        return journey;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflows.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWorkflows.fulfilled, (state, action) => {
      state.workflows = uniqBy([...state.workflows, ...action.payload], 'workflowId');
      state.loading = false;
    });
    builder.addCase(fetchWorkflows.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchWorkflowsForStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWorkflowsForStats.fulfilled, (state, action) => {
      const payloadArray = Array.isArray(action.payload) ? action.payload : [];
      state.workflows = uniqBy([...state.workflows, ...payloadArray], 'workflowId');
      state.loading = false;
    });
    builder.addCase(fetchWorkflowsForStats.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createUserWorkflow.pending, (state) => {
      state.userLoadingWorkflow = true;
      state.errorWorkflow = '';
    });
    builder.addCase(createUserWorkflow.fulfilled, (state, action) => {
      state.userNewWorkflow = action.payload;
      state.userLoadingWorkflow = false;
      state.errorWorkflow = '';
    });
    builder.addCase(createUserWorkflow.rejected, (state, action) => {
      state.userLoadingWorkflow = false;
      state.errorWorkflow =
        (action.payload as string) === serverError ? i18n.t(customError) : (action.payload as string);
    });
    builder.addCase(emptyNewWorkflow, (state) => {
      state.userNewWorkflow = null;
    });
    builder.addCase(clearErrorWorkflow, (state) => {
      state.errorWorkflow = '';
    });
  },
});

export default workflowsSlice.reducer;
