import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { WithChildrenProps } from '@app/types/generalTypes';
import { useKeycloak } from '@react-keycloak/web';
import { remove } from 'local-storage';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;
  const location = useLocation();

  useEffect(() => {
    if (authenticated && location) remove('redirectUrl');
  }, [authenticated, location]);

  return authenticated ? <>{children}</> : <Navigate to="/login" state={{ from: location }} />;
};

export default RequireAuth;
