import styled from 'styled-components/macro';
import { Typography, Divider } from 'antd';
import { MenuItem as MenuItemBase } from '@app/components/common/Menu/Menu';
import { media } from '@app/styles/themes/constants';

export const Text = styled(Typography.Text)`
  font-size: 0.875rem;
  font-weight: 600;

  & > a {
    display: flex;
    border-radius: 7px;
    margin-bottom: 0.625rem;
    background-color: rgba(var(--primary-rgb-color), 0.1);
    min-width: 130px;
    padding: 0 10px;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      // background-color: var(--lightgrey);
      background-color: rgba(var(--primary-rgb-color), 0.3);

      font-weight: bold;
    }

    svg {
      margin: 0 5px;
    }
  }

  @media only screen and (${media.md}) {
    font-size: 1rem;
  }
`;

export const MenuItem = styled(MenuItemBase)`
  margin: 0;
  .ant-menu-item-selected {
    background-color: unset !important;
  }
`;

export const ItemsDivider = styled(Divider).withConfig({
  shouldForwardProp: (prop) => !['eventKey', 'warnKey'].includes(prop),
})`
  margin: 0;
`;

export const FeedbackInstallWrapper = styled.div`
  padding: 0 1rem 0.75rem;
  .ant-btn-ghost {
    padding-bottom: 0;
  }
`;
