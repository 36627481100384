import React from 'react';
import * as S from './MoonSunSwitch.styles';
import { MoonIcon } from '@app/components/common/icons/MoonIcon';
import { SunIcon } from '@app/components/common/icons/SunIcon';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface MoonSunSwitchProps {
  isMoonActive: boolean;
  onClickMoon: () => void;
  onClickSun: () => void;
}

export const MoonSunSwitch: React.FC<MoonSunSwitchProps> = ({ isMoonActive, onClickMoon, onClickSun }) => {
  const { t } = useTranslation();

  return (
    <S.ButtonWrapper>
      <S.ButtonGroup $isFirstActive={isMoonActive}>
        <Tooltip title={t('header.darkTheme', { defaultValue: 'Dark theme' })} zIndex={10000}>
          <S.Btn size="small" type="link" icon={<MoonIcon />} onClick={onClickMoon} />
        </Tooltip>
        <Tooltip title={t('header.lightTheme', { defaultValue: 'Light theme' })} zIndex={10000}>
          <S.Btn size="small" type="link" icon={<SunIcon />} onClick={onClickSun} />
        </Tooltip>
      </S.ButtonGroup>
    </S.ButtonWrapper>
  );
};
