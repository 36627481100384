import React from 'react';
import styled from 'styled-components/macro';
import { Spinner } from '@app/components/common/Spinner/Spinner';

interface LoadingProps {
  size?: 'small' | 'large' | 'default' | undefined;
  tip?: string;
}

export const Loading: React.FC<LoadingProps> = ({ size, tip }) => {
  return (
    <SpinnerContainer>
      <Spinner tip={tip} size={size || 'large'} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
