import { INotification } from '@app/interfaces/interfaces';
import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { RootState } from '../store';
import { deleteNotificationServiceApi, getNotificationServiceApi } from '@app/api/http/notificationsService.api';

export interface NotificationsState {
  notifications: INotification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const appendNotification = createAction<PrepareAction<INotification>>(
  'notifications/appendNotification',
  (notification: INotification) => {
    return {
      payload: notification,
    };
  },
);

export const removeNotification = createAction<PrepareAction<INotification>>(
  'notifications/removeNotification',
  (notification: INotification) => {
    return {
      payload: notification,
    };
  },
);

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (_, { rejectWithValue }) =>
  getNotificationServiceApi()
    .then((res) => res.data || [])
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async (notificationId: string, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const { notifications } = state.notifications || { notifications: [] };
    return deleteNotificationServiceApi(notificationId)
      .then(() => notifications.filter((x) => x.id !== notificationId))
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      );
  },
);

export const clearNotifications = createAction('notifications/clearNotifications');

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(appendNotification, (state, { payload }) => {
      state.notifications = [payload, ...state.notifications];
    });
    builder.addCase(removeNotification, (state, { payload }) => {
      state.notifications = state.notifications.filter((x) => !isEqual(x, payload));
    });
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.notifications = [...payload];
    });
    builder.addCase(deleteNotification.fulfilled, (state, { payload }) => {
      state.notifications = [...payload];
    });
    builder.addCase(clearNotifications, (state) => {
      state.notifications = [];
    });
  },
});

export default notificationsSlice.reducer;
