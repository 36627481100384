import styled, { css } from 'styled-components';
import { Divider, Row } from 'antd';
import { Button } from 'components/common/buttons/Button/Button';
import { media } from '@app/styles/themes/constants';
import { FaCircle } from 'react-icons/fa';

export const MenuRow = styled(Row).withConfig({
  shouldForwardProp: (prop) => !['eventKey', 'warnKey'].includes(prop),
})`
  .empty__content {
    padding: 20px;
    .ant-empty-image {
      height: 50px;
    }
  }
  .list__item {
    gap: 0 !important;
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
  }
`;

export const HeaderMenu = styled.div`
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  background-color: var(--primary-color);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: var(--light-white-dark-black-color) !important;
    &.ant-typography {
      font-size: 18px;
    }
  }
  svg {
    color: var(--light-white-dark-black-color) !important;
    height: 20px;
    width: 19px;
  }
`;

export const NoticesOverlayMenu = styled.div`
  max-width: 15rem;

  @media only screen and (${media.md}) {
    max-width: 25rem;
  }
`;

export const SplitDivider = styled(Divider)`
  margin: 0 0.5rem;
`;

export const LinkBtn = styled(Button)`
  &.ant-btn {
    padding: 0;
    font-size: 0.875rem;
    height: unset;
    line-height: unset;
  }
`;

export const Btn = styled(Button)`
  width: 100%;
`;

export const NotificationConsent = styled.div`
  padding: 10px 10px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-space-align-center {
    align-items: baseline;
  }
  .notifications__provider-content {
    gap: 0 !important;
    padding-left: 5px;
    .ant-typography {
      padding-top: 5px;
    }
  }
  .notifications__consent-content {
    gap: 0 !important;
    padding-left: 5px;
    :hover {
      cursor: pointer;
    }
    .ant-typography {
      padding-top: 5px;
    }
  }

  .statusRejected {
    font-weight: bold;
    color: var(--red);
    font-size: larger;
  }
  .statusFinished {
    font-weight: bold;
    color: var(--green--text);
    font-size: larger;
  }

  .provider_header {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      width: 100%;
    }
  }

  .date {
    font-size: smaller;
    font-style: italic;
    font-weight: lighter;
    float: right;
  }

  .outputUrl {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }
  }
`;

export const Icon = styled(FaCircle)<{ $danger: boolean }>`
  color: var(--skyblue);
  ${({ $danger }) =>
    $danger &&
    css`
      color: var(--red);
    `}
`;
