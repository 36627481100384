import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { Col, MenuProps, Row, Tooltip } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { LoginOutlined } from '@ant-design/icons';
import { GrMenu } from 'react-icons/gr';
import { RiTwitterLine, RiYoutubeLine, RiLinkedinBoxLine } from 'react-icons/ri';
import { ProfileDropdown } from './components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { doLogin } from '@app/store/slices/authSlice';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { Dropdown } from '../common/Dropdown/Dropdown';
import { Button } from '../common/buttons/Button/Button';
import { useLocation } from 'react-router-dom';
import { BASIC_PATH } from '../router/AppRouter';
import FeedbackModal from './components/FeedbackModal/FeedbackModal';
import { NotificationsDropdown } from './components/notificationsDropdown/NotificationsDropdown';
import PreLoginMenu from './components/PreLoginMenu/PreLoginMenu';
import { useNotifications } from '@app/hooks/useNotifications';
import { useLanguage } from '@app/hooks/useLanguage';
import { LanguageType } from '@app/interfaces/interfaces';
import { SUPPORTED_LANGUAGES } from '@app/styles/themes/constants';
import * as S from './Header.styles';
import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

interface HeaderProps {
  toggleSider: () => void;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider }) => {
  const { isMediumMobile, isTablet, largerScreen } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const signIn = () => {
    const { pathname } = location.state?.from || { pathname: BASIC_PATH };
    dispatch(doLogin({ keycloak, pathname }));
  };
  const theme = useAppSelector((state) => state.theme.theme);

  const { language, setLanguage } = useLanguage();
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);
  const toggleHeaderMenu = () => setIsOpenHeaderMenu(!isOpenHeaderMenu);
  useNotifications();
  const items: MenuProps['items'] = [
    { label: 'English (en)', key: 'en' },
    { label: 'Deutsch (de)', key: 'de' },
    { label: 'Ελληνικά (el)', key: 'gr' },
    { label: 'Latviešu Valoda (lv)', key: 'lv' },
  ];

  const languageValue = (type: 'long' | 'short') => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedLang = (items.find((lang) => lang?.key === language) as any) || (items[0] as any);
    return (
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {type === 'long' && <span>{selectedLang?.label.split(' (')[0]} </span>}
        <span className={`countryCode ${type === 'short' ? 'countryShort' : ''}`}>{selectedLang?.key}</span>
      </span>
    );
  };

  const setLanguageCookie = (lang: LanguageType) => {
    const domain = window.location.hostname === 'localhost' ? 'localhost' : '.across-h2020.eu';
    const language = lang !== 'gr' ? lang : 'el';
    document.cookie = `lang=${language}; domain=${domain}; path=/`;
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setLanguage(key as LanguageType);
    setLanguageCookie(key as LanguageType);
  };

  useEffect(() => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('lang='))
      ?.split('=')[1];

    if (!cookieValue && language) {
      setLanguageCookie(language);
    }

    if (cookieValue && SUPPORTED_LANGUAGES.includes(cookieValue) && cookieValue !== language) {
      if (cookieValue === 'el') {
        setLanguage('gr');
      } else {
        setLanguage(cookieValue as LanguageType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {Boolean(keycloak.authenticated) && <FeedbackModal auto={true} />}
      <Row justify={'space-between'} align="middle" style={{ minWidth: '200px' }}>
        {keycloak.authenticated && (
          <S.ToggleSideMenu>
            <S.BurgerButton id="side_menu_toggle_btn" onClick={toggleSider} aria-label="Toggle Menu">
              <GrMenu />
            </S.BurgerButton>
          </S.ToggleSideMenu>
        )}
        {!keycloak.authenticated && (
          <S.WrapperHeaderPreLogin>
            <S.WrapperLogoHMenu>
              <img
                src={isOpenHeaderMenu || theme === 'dark' ? logo : logoDark}
                width={!largerScreen ? '145' : '100'}
                height="48"
                className="header--logo"
                alt="Across Logo"
              />
              <S.BurgerColHeaderMenu>
                <S.MobileBurgerHeaderMenu onClick={toggleHeaderMenu} isCross={isOpenHeaderMenu} />
              </S.BurgerColHeaderMenu>
              {isOpenHeaderMenu && (
                <S.PreLoginMenuWrapperBurger>
                  <PreLoginMenu
                    className="burger__menu"
                    setIsOpenHeaderMenu={setIsOpenHeaderMenu}
                    isOpenHeaderMenu={isOpenHeaderMenu}
                  />
                </S.PreLoginMenuWrapperBurger>
              )}
            </S.WrapperLogoHMenu>
            {/* )} */}
          </S.WrapperHeaderPreLogin>
        )}
        {isTablet ? (
          <S.ProfileColumn>
            <Row align="middle" justify="end">
              <Col>
                <S.ProfileRow className={!keycloak.authenticated ? 'withGap' : ''}>
                  <Row>
                    {!keycloak.authenticated && (
                      <S.WrapperHeaderSocialMedia>
                        <S.HeaderItemWrapper>
                          <S.HeaderItem>
                            <Tooltip title="Twitter">
                              <a
                                href="https://twitter.com/AcrossH2020"
                                target="blank"
                                type="link"
                                className="socialMedia"
                                aria-label="Twitter"
                              >
                                <RiTwitterLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                            <Tooltip title="Youtube">
                              <a
                                href="https://www.youtube.com/channel/UCdF9AF8eeoaT1cJJ9Tt2gYA"
                                target="blank"
                                type="link"
                                className="socialMedia"
                                aria-label="Header Youtube link"
                              >
                                <RiYoutubeLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                            <Tooltip title="Linkedin">
                              <a
                                href="https://www.linkedin.com/company/across-project-h2020/"
                                target="blank"
                                type="link"
                                className="socialMedia"
                                aria-label="Header Linkedin link"
                              >
                                <RiLinkedinBoxLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                          </S.HeaderItem>
                        </S.HeaderItemWrapper>
                      </S.WrapperHeaderSocialMedia>
                    )}
                    {/* <Col>
                      <HeaderFullscreen />
                    </Col> */}
                    {keycloak.authenticated && (
                      <Col>
                        <NotificationsDropdown />
                      </Col>
                    )}
                    <S.LanguageCol>
                      <Tooltip title={t('header.changeLanguage', { defaultValue: 'Change language' })} placement="left">
                        <Dropdown menu={{ items, onClick, id: 'language' }} trigger={['click']}>
                          <a onClick={(e) => e.preventDefault()}>
                            <Button id="language_button">{languageValue('long')}</Button>
                          </a>
                        </Dropdown>
                      </Tooltip>
                    </S.LanguageCol>
                    {!keycloak.authenticated && (
                      <Col>
                        <Tooltip title={t('header.login', { defaultValue: 'Sign In' })}>
                          <S.DivWrapper onClick={signIn}>
                            <Button icon={<LoginOutlined />} id="sign_in_button">
                              {t('header.login', { defaultValue: 'Sign In' })}
                            </Button>
                          </S.DivWrapper>
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </S.ProfileRow>
              </Col>
              {keycloak.authenticated && (
                <Col style={{ maxHeight: '50px' }}>
                  <ProfileDropdown />
                </Col>
              )}
            </Row>
          </S.ProfileColumn>
        ) : (
          <>
            <Row style={{ minWidth: '170px' }}>
              <Col>
                <S.ProfileRow className={!keycloak.authenticated ? 'withGap' : ''}>
                  <Row align="middle" gutter={[10, 10]}>
                    {keycloak.authenticated && (
                      <Col>
                        <NotificationsDropdown />
                      </Col>
                    )}
                    {!keycloak.authenticated && (
                      <S.WrapperHeaderSocialMedia>
                        <S.HeaderItemWrapper>
                          <S.HeaderItem>
                            <Tooltip title="Twitter">
                              <a
                                href="https://twitter.com/AcrossH2020"
                                target="blank"
                                type="link"
                                className="socialMedia"
                              >
                                <RiTwitterLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                            <Tooltip title="Youtube">
                              <a
                                href="https://www.youtube.com/channel/UCdF9AF8eeoaT1cJJ9Tt2gYA"
                                target="blank"
                                type="link"
                                className="socialMedia"
                              >
                                <RiYoutubeLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                            <Tooltip title="Linkedin">
                              <a
                                href="https://www.linkedin.com/company/across-project-h2020/"
                                target="blank"
                                type="link"
                                className="socialMedia"
                              >
                                <RiLinkedinBoxLine className="socialMediaIcon" />
                              </a>
                            </Tooltip>
                          </S.HeaderItem>
                        </S.HeaderItemWrapper>
                      </S.WrapperHeaderSocialMedia>
                    )}
                    <S.LanguageCol>
                      <Dropdown menu={{ items, onClick }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                          <Button className="buttonShort">{languageValue('short')}</Button>
                        </a>
                      </Dropdown>
                    </S.LanguageCol>
                    {!keycloak.authenticated && (
                      <Col>
                        <Tooltip title={t('header.login', { defaultValue: 'Sign in' })}>
                          <S.DivWrapper onClick={signIn}>
                            <Button
                              icon={!isMediumMobile ? <LoginOutlined /> : null}
                              id="sign_in_button"
                              className="smallLoginBtn"
                            >
                              {t('header.login', { defaultValue: 'Sign In' })}
                            </Button>
                          </S.DivWrapper>
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </S.ProfileRow>
              </Col>
              <Col>
                <ProfileDropdown />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
};
