import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { MenuProps } from 'antd';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogout } from '@app/store/slices/authSlice';
import { useKeycloak } from '@react-keycloak/web';
// import { Button } from '@app/components/common/buttons/Button/Button';
// import FeedbackModal from '../../FeedbackModal/FeedbackModal';
import { ThemePicker } from '../ThemePicker/ThemePicker';
import { LogoutOutlined } from '@ant-design/icons';
// import { emitOpen } from '@app/store/slices/feedbackSlice';

type MenuItem = Required<MenuProps>['items'][number];

interface ProfileOverlayProps {
  $handleOpenChange: () => void;
}
const getItem = (label: React.ReactNode, key: React.Key): MenuItem => {
  return {
    key,
    label,
  } as MenuItem;
};

export const ProfileOverlay: React.FC<ProfileOverlayProps> = ({ $handleOpenChange, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const logout = () => {
    navigate('/');
    dispatch(doLogout(keycloak));
  };

  // const feedbackRef = useRef<{ setOpen: () => void }>(null);
  // const openFeedbackModal = () => {
  //   dispatch(emitOpen(null));
  //   feedbackRef && feedbackRef.current ? feedbackRef.current.setOpen() : null;
  // };

  const items: MenuItem[] = [
    getItem(
      <S.Text>
        <Link onClick={logout} to="#">
          <LogoutOutlined />
          {t('header.logout', { defaultValue: 'Logout' })}
        </Link>
      </S.Text>,
      2,
    ),
  ];

  return (
    <>
      <ThemePicker />
      {/* {keycloak.authenticated && (
        <S.FeedbackInstallWrapper>
          <Button block type="primary" onClick={openFeedbackModal}>
            {t('header.feedback', { defaultValue: 'Feedback' })}
          </Button>
        </S.FeedbackInstallWrapper>
      )}
      <FeedbackModal ref={feedbackRef} /> */}
      <DropdownMenu onSelect={$handleOpenChange} selectedKeys={[]} {...props} items={items} />{' '}
    </>
  );
};
