import { IFeedback } from '@app/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

export const sendFeedbackApi = (feedbackPayload: IFeedback): Promise<AxiosResponse> =>
  httpApi.post('feedback', { ...feedbackPayload });

export const sendFeedbackApprovalStatusApi = (feedbackPayload: IFeedback): Promise<AxiosResponse> =>
  httpApi.put('feedback', { ...feedbackPayload });

export const getFeedbacksApi = (): Promise<AxiosResponse> => httpApi.get<IFeedback[]>('feedback');
