import React, { useEffect } from 'react';
import { Empty, Space, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { UserStoriesAdmin } from '../login/UserStories/UserStoriesAdmin/UserStoriesAdmin';
import { getFeedbacks } from '@app/store/slices/feedbackSlice';
import * as S from './AdminPage.styles';
import Breadcrumbs from '@app/components/common/Breadcrumb/Breadcrumb';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const AdminPage: React.FC = () => {
  const user = useAppSelector((state) => state.userInfo.user);
  const dispatch = useAppDispatch();
  const { feedbacks, loading } = useAppSelector((state) => state.feedback);
  const feedbackPending = feedbacks.filter((item) => item.approve === 'pending');
  const feedbackNotPending = feedbacks.filter((item) => item.approve !== 'pending');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getFeedbacks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storiesListNotApproved = (
    <div>
      {feedbackPending.length > 0 ? (
        <S.UserStoriesWrapper>
          {feedbackPending.map((item) => (
            <UserStoriesAdmin key={item._id} {...item} />
          ))}
        </S.UserStoriesWrapper>
      ) : (
        <Empty description={'No Pending User Stories'} />
      )}
    </div>
  );

  const storiesListApproved = (
    <div>
      {feedbackNotPending.length > 0 ? (
        <S.UserStoriesWrapper>
          {feedbackNotPending.map((item) => (
            <UserStoriesAdmin key={item._id} {...item} />
          ))}
        </S.UserStoriesWrapper>
      ) : (
        <Empty description={'No User Stories'} />
      )}
    </div>
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('adminMenu.pendingStories', { defaultValue: 'Pending User Stories' }),
      children: storiesListNotApproved,
    },
    {
      key: '2',
      label: t('adminMenu.allStories', { defaultValue: 'All User Stories (except Pending)' }),
      children: storiesListApproved,
    },
  ];

  return (
    <>
      {user?.admin_role ? (
        <S.MainWrapper>
          <PageTitle>{t('adminMenu.adminPage', { defaultValue: 'Admin page' })}</PageTitle>
          <S.Header>
            <Space.Compact block>
              <Breadcrumbs />
            </Space.Compact>
          </S.Header>
          {loading ? <Spinner size="large"></Spinner> : <Tabs type="card" defaultActiveKey="1" items={items} />}
        </S.MainWrapper>
      ) : (
        <S.Unauthorized>Sorry, you are not authorized to access this screen</S.Unauthorized>
      )}
    </>
  );
};

export default AdminPage;
