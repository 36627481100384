import styled from 'styled-components/macro';
import { Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  .ant-modal-header {
    background: var(--modal-header-background-color);
  }

  .ant-modal-close {
    color: var(--modal-close-icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }

  .applicationFormModal {
    .ant-modal-header {
      color: #000000 !important;
    }
  }
`;
