import { NotificationType } from '@app/components/common/Notification/Notification';
import i18n from '@app/i18n';
import { IChannel, IJourney, ILocaleValues, IWorkflow, KeycloakToken, UserModel } from '@app/interfaces/interfaces';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { IOptions } from 'sanitize-html';
const DEFAULT_KEYCLOACK_ROLE = 'citizen';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const capitalize = (word: string): string => `${word[0]?.toUpperCase() || ''}${word?.slice(1) || ''}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const getUserInfo = (keycloakTokenParsed: KeycloakToken): UserModel | null => {
  const { sub, azp, realm_access, given_name, family_name, preferred_username, email, resource_access } =
    keycloakTokenParsed;
  return sub && azp && realm_access && family_name && email
    ? {
        _id: sub,
        from: azp,
        role: realm_access?.roles[0] ?? DEFAULT_KEYCLOACK_ROLE,
        admin_role: resource_access?.['across-citizen-web-app']?.roles.some((x) => x === 'citizen-fe-admin') ?? false,
        data: {
          givenName: given_name,
          familyName: family_name,
          preferredUsername: preferred_username,
          email: email,
        },
      }
    : null;
};

export const getStatus = (x: IJourney): string => {
  const status = x.workflowStatus;
  return status ? status.charAt(0).toUpperCase() + status.slice(1) : 'Unknown';
};
export const getStatusColor = (x: IJourney): string => {
  const status = x.workflowStatus;
  switch (status) {
    case 'finished':
      return BASE_COLORS.gray;
    case 'killed':
      return BASE_COLORS.red;
    case 'pending':
      return BASE_COLORS.orange;
    case 'running':
      return BASE_COLORS.cyan;
    default:
      return 'transparent';
  }
};

export const getLocation = (location: string): string => {
  const value = String(location).toLowerCase();
  switch (value) {
    case 'greece':
    case 'grc-greece':
      return 'GR';
    case 'germany':
    case 'deu-germany':
      return 'DE';
    case 'latvia':
    case 'lva-latvia':
      return 'LV';
    case 'eur-european union':
      return 'EU';
    default:
      return '';
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toCamelCaseNotation = (str: string): string => {
  //split str by all non-alphabetic chars
  const splittedString = str.toLowerCase().split(/[^a-z]/i);

  return splittedString.join('-');
};

export const isServiceDigital = (channelType: IChannel): boolean | undefined => {
  // const nonDigitalServices = ['Service Bureau', "Client's Location", 'Citizen Service Centres'];
  const digitalServices = [
    //TODO: check if these are valid for digital services
    'RESTService',
    'AsyncRESTService',
    'E-mail',
    'Homepage',
    'Fax',
    'Assistant',
    'Telephone',
    'Mobile App',
    'Digital TV',
    'Mail',
  ];

  if (digitalServices.indexOf(channelType) !== -1) {
    return true;
  } else {
    return false;
  }
};

export const getServiceCategory = (channelType: IChannel): 'restService' | 'redirectService' | 'offlineService' => {
  switch (channelType) {
    case 'RESTService':
    case 'AsyncRESTService':
      return 'restService';
    case 'Homepage':
    case 'Mobile App':
    case 'Digital TV':
      return 'redirectService';
    default:
      return 'offlineService';
  }
};

export const allowNoHtmlChars = {
  //remove all HTML attributes (sanitize-html library)
  allowedTags: [], // Remove all tags
  allowedAttributes: {}, // Remove all attributes
};

export const allowSpecificHtmlChars: IOptions = {
  allowedTags: ['span', 'div', 'b', 'i', 'a', 'p', 'ul', 'li', 'ol', 'br', 'hr'],
  allowedAttributes: {
    '*': ['style'],
    a: ['href', 'rel', 'target'],
  },
  // allowedSchemes: true,
  textFilter: function (text) {
    // Allow specific characters to be unescaped
    return text.replace(/&amp;/g, '&');
  },
  transformTags: {
    '*': (tagName, attribs) => {
      const attrToAllow = ['font-weight', 'font-style'];
      const sanitizedStyles = attribs?.style;
      let allowedStyles = '';
      if (sanitizedStyles) {
        const split = sanitizedStyles.split(';');
        split.forEach((spl) => {
          const properties = spl.split(':')[0].toString().toLowerCase().trim();
          if (attrToAllow.indexOf(properties) !== -1) {
            allowedStyles = allowedStyles.concat(spl.toString()).concat(';');
          }
        });
        return {
          tagName,
          attribs: {
            style: allowedStyles,
          },
        };
      }
      return {
        tagName,
        attribs,
      };
    },
  },
};

export const getCurrentLanguage = (): string => {
  return i18n.language === 'gr' ? 'el' : i18n.language;
};

export const serviceDescriptionLocale = (description: ILocaleValues[]): string => {
  if (description && description.length > 0) {
    return (
      description?.find((d) => d.locale === getCurrentLanguage())?.value ||
      description?.find((d) => d.locale === 'en')?.value ||
      description[0]?.value ||
      ''
    );
  } else {
    return '';
  }
};

export const countryLocale = (country: string): string => {
  const value = String(country).toLowerCase();
  switch (value) {
    case 'grc-greece':
    case 'greece':
      return 'countries.greece';
    case 'deu-germany':
    case 'germany':
      return 'countries.germany';
    case 'lva-latvia':
    case 'latvia':
      return 'countries.latvia';
    default:
      return '';
  }
};

export const toUpperCaseCustom = (text: string): string => {
  if (i18n.language === 'gr') {
    //remove all accents from uppercase Greek text
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  } else {
    return text.toUpperCase();
  }
};

export const getValueLocale = (localeArray: ILocaleValues[]): string => {
  if (localeArray && localeArray.length > 0) {
    return (
      localeArray.find((elem) => elem.locale === getCurrentLanguage() && elem.value.trim() !== '')?.value ??
      localeArray.find((elem) => elem.locale === 'en' && elem.value.trim() !== '')?.value ??
      localeArray.find((elem) => elem.value !== '')?.value ??
      ''
    );
  } else {
    return '';
  }
};

export const sameWidthButtons = (id1: string, id2: string): void => {
  setTimeout(() => {
    const btn1 = document.getElementById(id1);
    const btn2 = document.getElementById(id2);
    btn1 && (btn1.style.width = 'auto');
    btn2 && (btn2.style.width = 'auto');
    const width1 = btn1?.offsetWidth;
    const width2 = btn2?.offsetWidth;
    const maxWidth = width1 && width2 && Math.max(width1, width2);
    if (maxWidth) {
      btn2.style.width = `${maxWidth}px`;
      btn1.style.width = `${maxWidth}px`;
    }
  }, 100);
};

export const handleScrollTo = (id: string): void => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showIncludedUrlsAsLinks = (text: string): any => {
  const linkMark = '[Link:';
  const regex = '\\[Link:';
  const occurrences = text.match(new RegExp(String.raw`${regex}`, 'g')) ?? [];
  let textToSearch = text;

  const parts = [];

  if (occurrences.length > 0) {
    for (let i = 0; i < occurrences?.length; i++) {
      const textStartIndex = textToSearch.indexOf(linkMark);
      parts.push(['text', textToSearch.substring(0, textStartIndex)]);

      const textEndIndex = textToSearch.indexOf(']');
      const linkText = textToSearch.substring(textStartIndex + linkMark.length, textEndIndex);

      const textToSearch2 = textToSearch.substring(textEndIndex);
      const urlEndIndex = textToSearch2.indexOf(')');
      const url = textToSearch2.substring(textToSearch2.indexOf('(') + 1, urlEndIndex);
      parts.push(['html', url, linkText]);
      textToSearch = textToSearch2.substring(urlEndIndex + 1);

      if (i === occurrences.length - 1) {
        parts.push(['text', textToSearch]);
      }
    }
  }

  if (parts.length === 0) {
    return text;
  } else {
    return (
      <div>
        {parts.map((part, index) => {
          if (part[0] === 'text') {
            return <span key={index}>{part[1]}</span>;
          } else {
            return (
              <a key={index} href={part[1]} target="_blank" rel="noreferrer">
                {part[2]}
              </a>
            );
          }
        })}
      </div>
    );
  }
};

export const getTotalCountries = (workflows: IWorkflow[]): string[] => {
  const totalCountries = new Set<string>();

  if (workflows) {
    workflows.forEach((w) => {
      if (w.countryOrigin.trim() !== '') {
        totalCountries.add(w.countryOrigin);
      }
      if (w.countryDestination.trim() !== '') {
        totalCountries.add(w.countryDestination);
      }
    });
  }

  return Array.from(totalCountries);
};

export const availableLanguages = [
  { label: 'English (en)', key: 'gb' },
  { label: 'Deutsch (de)', key: 'de' },
  { label: 'Ελληνικά (el)', key: 'gr' },
  { label: 'Latviešu Valoda (lv)', key: 'lv' },
];
