import { IJourneyPost, IWorkflow } from '@app/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

const BASE_SUB_PATH = 'workflowExecutionManagement';

export const getWorkflowsApi = (): Promise<AxiosResponse> =>
  httpApi.get<IWorkflow[]>(`${BASE_SUB_PATH}/workflowManagement`);

export const getWorkflowsForStatsApi = (): Promise<AxiosResponse> =>
  httpApi.get<IWorkflow[]>(`workflows/workflowManagement`);

export const postUserWorkflowsApi = (data: IJourneyPost): Promise<AxiosResponse> =>
  httpApi.post<IJourneyPost>(`${BASE_SUB_PATH}/createUserWorkflowInstance`, data);

export const getWorkflowApi = (workflowInstanceId: string): Promise<AxiosResponse> =>
  httpApi.get<IWorkflow[]>(
    `${BASE_SUB_PATH}/getUserWorkflowInstanceById?workflowInstanceId=${encodeURI(workflowInstanceId)}`,
  );

export const setActionStatusApi = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  status: string,
): Promise<AxiosResponse> =>
  httpApi.put(
    `${BASE_SUB_PATH}/setActionStatus?workflowInstanceId=${encodeURI(workflowInstanceId)}&phaseId=${encodeURI(
      phaseId,
    )}&actionId=${encodeURI(actionId)}&status=${encodeURI(status)}`,
  );

export const setServiceStatusApi = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  serviceId: string,
  status: string,
): Promise<AxiosResponse> =>
  httpApi.put(
    `${BASE_SUB_PATH}/setServiceStatus?workflowInstanceId=${encodeURI(workflowInstanceId)}&phaseId=${encodeURI(
      phaseId,
    )}&actionId=${encodeURI(actionId)}&serviceId=${encodeURI(serviceId)}&status=${encodeURI(status)}`,
  );
