import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getFavoriteJourneyServiceApi,
  getFavoriteServiceApi,
  putFavoriteServiceApi,
} from '@app/api/http/favoriteService.api';
import { RootState } from '../store';
import { isEmpty } from 'lodash';

interface IFavorite {
  workflowInstanceId: string;
  phaseId: string;
  actionId: string;
  serviceId: string;
}

interface FavoriteSlice {
  favorites: IFavorite[];
  loadingFavorites: boolean;
}

const initialState: FavoriteSlice = {
  favorites: [],
  loadingFavorites: false,
};

export const markFavoriteServiceJourney = createAsyncThunk(
  'journey/markFavoriteServiceJourney',
  (
    {
      workflowInstanceId,
      phaseId,
      actionId,
      serviceId,
    }: { workflowInstanceId: string; phaseId: string; actionId: string; serviceId: string },
    { rejectWithValue },
  ) =>
    putFavoriteServiceApi(workflowInstanceId, phaseId, actionId, serviceId)
      .then((res) => res.data)
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);
export const getFavoriteJourneyService = createAsyncThunk(
  'journey/getFavoriteJourneyService',
  (workflowInstanceId: string, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const { favorites } = state.favorite || { favorites: [] };
    const hasNoFavorite = isEmpty(favorites.find((x) => x.workflowInstanceId === workflowInstanceId));
    return hasNoFavorite
      ? getFavoriteJourneyServiceApi(workflowInstanceId)
          .then((res) => res.data)
          .catch((error) =>
            rejectWithValue(
              (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString(),
            ),
          )
      : [];
  },
);

export const getFavoriteServices = createAsyncThunk('journey/getFavoriteServices', (_, { rejectWithValue }) =>
  getFavoriteServiceApi()
    .then((res) => res.data)
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

const selectFavorite = (state: RootState) => ({
  favorites: state.favorite.favorites,
});
export const selectFavoriteByJourney = createSelector(
  [selectFavorite, (_, workflowInstanceId: string) => workflowInstanceId],
  ({ favorites }, workflowInstanceId) => ({
    favorites: favorites.filter((x) => x.workflowInstanceId === workflowInstanceId) || [],
  }),
);

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(markFavoriteServiceJourney.fulfilled, (state, action) => {
      state.favorites = [...action.payload];
    });
    builder.addCase(getFavoriteJourneyService.fulfilled, (state, action) => {
      state.favorites = [...state.favorites, ...action.payload];
    });
    builder.addCase(getFavoriteServices.pending, (state) => {
      state.loadingFavorites = true;
    });
    builder.addCase(getFavoriteServices.fulfilled, (state, action) => {
      state.favorites = [...action.payload];
      state.loadingFavorites = false;
    });
    builder.addCase(getFavoriteServices.rejected, (state) => {
      state.loadingFavorites = false;
    });
  },
});

export default favoriteSlice.reducer;
