import { IHistory } from '@app/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

export const getHistoryApi = (
  pageNo: number,
  pageSize: number,
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  serviceId?: string,
): Promise<AxiosResponse> =>
  httpApi.get<IHistory[]>(
    `logs?workflowInstanceId=${workflowInstanceId}&phaseId=${phaseId}&actionId=${actionId}${
      serviceId ? `&serviceId=${serviceId}` : ''
    }&pageNo=${pageNo}&pageSize=${pageSize}`,
  );

export const getStatisticsInfoApi = (): Promise<AxiosResponse> =>
  httpApi.get<{
    numUsersStartedJourney: number;
    numStartedJourneys: number;
  }>(`statistics/StatisticsInfo`);
