import { css } from 'styled-components/macro';

export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 1.25rem;
    // border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  body {
    font-weight: 600;

    // ************* UserWay widget styling *******************
    #userwayAccessibilityIcon {
      border: 3px solid var(--white) !important;
    }

    #userwayAccessibilityIcon:focus-within {
      outline: 1px solid #01509a !important;
    }

    .userway_buttons_wrapper {
      top: auto !important;
      bottom: 6px !important;
      left: 35px !important;
      right: calc(100vw - 10px) !important;
    }

    .uwy .uai {
      width: 53px !important;
      height: 53px !important;

      img:not(.check_on) {
        width: 53px !important;
        height: 53px !important;
      }
    }

    // ********************************************************
  }

  img {
    display: block;
  }

  .ant-btn-primary {
    color: var(--light-white-dark-black-color);
    &:hover {
      color: var(--light-white-dark-black-color);
    }
    &:focus {
      color: var(--light-white-dark-black-color);
    }
  }
`;
