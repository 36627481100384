import { IJourney } from '@app/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { httpApi } from '../config/http.api';

const BASE_SUB_PATH = 'workflowExecutionManagement';

export const getJourneysApi = (): Promise<AxiosResponse> =>
  httpApi.get<IJourney[]>(`${BASE_SUB_PATH}/getUserWorkflowInstances`);

export const deleteJourneysApi = (workflowInstanceId: string): Promise<AxiosResponse> =>
  httpApi.delete<string>(`${BASE_SUB_PATH}/${workflowInstanceId}`);

export const editJourneyApi = (workflowInstanceId: string, name: string): Promise<AxiosResponse> =>
  httpApi.put<string>(
    `${BASE_SUB_PATH}/workflowName?${encodeURI(`name=${name}&workflowInstanceId=${workflowInstanceId}`)}`,
  );

export const finishWorkflowInstance = (workflowInstanceId: string): Promise<AxiosResponse> =>
  httpApi.put<string>(
    `${BASE_SUB_PATH}/finishWorkflowInstance?${encodeURI(`workflowInstanceId=${workflowInstanceId}`)}`,
  );
