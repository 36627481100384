import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet } from 'react-router-dom';
import { References } from '@app/components/common/References/References';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';

interface MainSiderProps {
  hasSidebar?: boolean;
}
const TIMEOUT = 300;
const MainLayout: React.FC<MainSiderProps> = ({ hasSidebar }) => {
  const { largerScreen, smallScreen } = useResponsive();
  const [siderCollapsed, setSiderCollapsed] = useState(largerScreen ? true : false);
  const scroller = useRef<HTMLDivElement>(null);
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);
  const { scroll } = useAppSelector((state) => state.actions);

  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        if (scroller && scroller.current) scroller.current.scrollTop = scroller.current.scrollHeight;
      }, TIMEOUT);
    }
  }, [scroll]);

  return (
    <S.LayoutMaster>
      {hasSidebar && <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />}
      <S.LayoutMain $hasSidebar={hasSidebar}>
        <MainHeader>
          <Header toggleSider={toggleSider} />
        </MainHeader>
        <MainContent ref={scroller} id="main-content">
          <S.Content>
            <Outlet />
          </S.Content>
          {smallScreen && <References isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />}
        </MainContent>
        {!smallScreen && <References isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />}
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
