import { deleteJourneysApi, editJourneyApi, finishWorkflowInstance, getJourneysApi } from '@app/api/http/journeys.api';
import { getWorkflowApi, setActionStatusApi, setServiceStatusApi } from '@app/api/http/workflows.api';
import { IActionStatus, IJourney, IServiceStatus } from '@app/interfaces/interfaces';
import { PrepareAction, createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { filter, find, map, uniqBy } from 'lodash';
import { RootState } from '../store';
import { emitOpen } from './feedbackSlice';
export interface IJourneyEdit {
  name: string;
  workflowInstanceId: string;
}

export interface JourneysState {
  journeys: IJourney[];
  loading: boolean;
  loadingJourney: boolean;
  errorJourney: string;
  deletingId: string | null;
  editingId: string | null;
  stoppingId: string | null;
  reloadUjoPage: boolean;
}

const initialState: JourneysState = {
  journeys: [],
  loading: false,
  loadingJourney: false,
  errorJourney: '',
  deletingId: null,
  editingId: null,
  stoppingId: null,
  reloadUjoPage: true,
};

const refactorJourneyState = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  serviceId: string,
  status: IServiceStatus,
  currentJourney: IJourney,
) =>
  currentJourney.workflowInstanceId === workflowInstanceId
    ? {
        ...currentJourney,
        workflowContent: currentJourney.workflowContent.map((y) =>
          y.phase_id === phaseId
            ? {
                ...y,
                actions: y.actions.map((z) =>
                  z.action_id === actionId
                    ? {
                        ...z,
                        touchpoints: z.touchpoints.map((k) =>
                          k.service_id === serviceId ? { ...k, status: status } : k,
                        ),
                      }
                    : z,
                ),
              }
            : y,
        ),
      }
    : currentJourney;

const refactorJourneyAction = (
  workflowInstanceId: string,
  phaseId: string,
  actionId: string,
  status: IActionStatus,
  currentJourney: IJourney,
) =>
  currentJourney.workflowInstanceId === workflowInstanceId
    ? {
        ...currentJourney,
        workflowContent: currentJourney.workflowContent.map((y) =>
          y.phase_id === phaseId
            ? {
                ...y,
                actions: y.actions.map((z) =>
                  z.action_id === actionId
                    ? {
                        ...z,
                        status: status,
                      }
                    : z,
                ),
              }
            : y,
        ),
      }
    : currentJourney;

export const refactorStateServiceJourney = createAsyncThunk(
  'journeys/refactorStateServiceJourney',
  (
    {
      workflowInstanceId,
      phaseId,
      actionId,
      serviceId,
      status,
    }: { workflowInstanceId: string; phaseId: string; actionId: string; serviceId: string; status: IServiceStatus },
    { getState },
  ) => {
    const state = getState() as RootState;
    const journeys = state.journeys.journeys || [];
    const journey = find(journeys, { workflowInstanceId });
    return journey
      ? uniqBy(
          [refactorJourneyState(workflowInstanceId, phaseId, actionId, serviceId, status, journey), ...journeys],
          'workflowInstanceId',
        )
      : journeys;
  },
);

export const refactorConsentServiceJourneys = createAsyncThunk(
  'journeys/refactorConsentServiceJourneys',
  async ({ serviceId }: { serviceId: string }, { getState }) => {
    const state = getState() as RootState;
    const journeys: IJourney[] = state.journeys.journeys || [];
    return [
      ...map(journeys, (journey) => ({
        ...journey,
        servicesWithoutConsent: filter(journey.servicesWithoutConsent, (x) => x.serviceId !== serviceId),
      })),
    ];
  },
);

export const setReloadUjoPage = createAction<PrepareAction<boolean>>(
  'journeys/setReloadUjoPage',
  (reloadUjoPage: boolean) => {
    return {
      payload: reloadUjoPage,
    };
  },
);

export const fetchJourney = createAsyncThunk(
  'journeys/fetchJourney',
  async (
    { workflowInstanceId, cb, reloadUjoPage }: { workflowInstanceId: string; cb: () => void; reloadUjoPage: boolean },
    { rejectWithValue, getState, dispatch },
  ) => {
    dispatch(setReloadUjoPage(reloadUjoPage));
    return getWorkflowApi(workflowInstanceId)
      .then((res) => {
        const state = getState() as RootState;
        const journeys = state.journeys.journeys || [];
        const journey = res.data;
        return uniqBy([journey, ...journeys], 'workflowInstanceId');
      })
      .catch((error) => {
        cb();
        return rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        );
      });
  },
);

export const editJourneyService = createAsyncThunk(
  'journeys/editJourneyService',
  async (
    {
      workflowInstanceId,
      phaseId,
      actionId,
      serviceId,
      status,
    }: {
      workflowInstanceId: string;
      phaseId: string;
      actionId: string;
      serviceId: string;
      status: string;
    },
    { rejectWithValue, getState },
  ) =>
    setServiceStatusApi(workflowInstanceId, phaseId, actionId, serviceId, status)
      .then((res) => {
        const state = getState() as RootState;
        const { workflowInstanceId, phaseId, actionId, serviceId, status } = res.data;
        const journeys = state.journeys.journeys || [];
        const journey = find(journeys, { workflowInstanceId });
        return journey
          ? uniqBy(
              [refactorJourneyState(workflowInstanceId, phaseId, actionId, serviceId, status, journey), ...journeys],
              'workflowInstanceId',
            )
          : journeys;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const editActionJourney = createAsyncThunk(
  'journeys/editActionJourney',
  async (
    {
      workflowInstanceId,
      phaseId,
      actionId,
      status,
    }: { workflowInstanceId: string; phaseId: string; actionId: string; status: string },
    { rejectWithValue, getState },
  ) =>
    setActionStatusApi(workflowInstanceId, phaseId, actionId, status)
      .then((res) => {
        const state = getState() as RootState;
        const { workflowInstanceId, phaseId, actionId, status } = res.data;
        const journeys = state.journeys.journeys;
        const journey = find(journeys, { workflowInstanceId });
        return journey
          ? uniqBy(
              [refactorJourneyAction(workflowInstanceId, phaseId, actionId, status, journey), ...journeys],
              'workflowInstanceId',
            )
          : journeys;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const editJourney = createAsyncThunk(
  'journeys/editJourney',
  async (data: IJourneyEdit, { rejectWithValue, getState }) =>
    editJourneyApi(data.workflowInstanceId, data.name)
      .then((res) => {
        const state = getState() as RootState;
        const journeys = state.journeys.journeys || [];

        // temp fix for rename errors
        res.data['workflowContent'] = JSON.parse(res.data.workflowContent);
        res.data.description = JSON.parse(res.data.description);
        //

        const journey = res.data;
        return uniqBy([journey, ...journeys], 'workflowInstanceId');
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const finishJourney = createAsyncThunk(
  'journeys/finishJourney',
  async (
    { finishedJourney, showFeedbackModal }: { finishedJourney: IJourney; showFeedbackModal: boolean },
    { rejectWithValue, getState, dispatch },
  ) =>
    finishWorkflowInstance(finishedJourney.workflowInstanceId)
      .then((res) => {
        const state = getState() as RootState;
        const journeys = state.journeys.journeys || [];
        const { workflowInstanceId, status: workflowStatus } = res.data;
        const journey = find(journeys, { workflowInstanceId });
        const data = journey ? uniqBy([{ ...journey, workflowStatus }, ...journeys], 'workflowInstanceId') : journeys;
        showFeedbackModal && dispatch(emitOpen(finishedJourney));
        return data;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const fetchJourneys = createAsyncThunk('journeys/fetchJourneys', async (_, { getState, rejectWithValue }) =>
  getJourneysApi()
    .then((res) => {
      const state = getState() as RootState;
      const journeys = state.journeys.journeys || [];
      const journeysRes = res.data;
      return uniqBy([...journeysRes, ...journeys], 'workflowInstanceId');
    })
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const deleteJourney = createAsyncThunk(
  'journeys/deleteJourney',
  async (workflowInstanceId: string, { rejectWithValue, getState }) =>
    deleteJourneysApi(workflowInstanceId)
      .then(() => {
        const state = getState() as RootState;
        const journeys = state.journeys.journeys || [];
        return filter(journeys, (x) => x.workflowInstanceId !== workflowInstanceId);
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

const selectJourney = (state: RootState) => ({
  journeys: state.journeys.journeys,
  loadingJourney: state.journeys.loadingJourney,
  reloadUjoPage: state.journeys.reloadUjoPage,
});

export const selectJourneyInfo = createSelector(
  [selectJourney, (_, workflowInstanceId: string) => workflowInstanceId],
  ({ journeys, loadingJourney, reloadUjoPage }, workflowInstanceId) => ({
    journey: find(journeys, (x) => x.workflowInstanceId === workflowInstanceId) || null,
    loadingJourney,
    reloadUjoPage,
  }),
);

const journeysSlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJourneys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJourneys.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
      state.loading = false;
    });
    builder.addCase(fetchJourneys.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchJourney.pending, (state) => {
      state.loadingJourney = true;
    });
    builder.addCase(fetchJourney.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
      state.loadingJourney = false;
    });
    builder.addCase(fetchJourney.rejected, (state) => {
      state.loadingJourney = false;
    });
    builder.addCase(deleteJourney.pending, (state, { meta }) => {
      state.deletingId = meta.arg;
    });
    builder.addCase(deleteJourney.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
      state.deletingId = null;
    });
    builder.addCase(deleteJourney.rejected, (state) => {
      state.deletingId = null;
    });
    builder.addCase(editJourney.pending, (state, { meta }) => {
      state.editingId = meta.arg.workflowInstanceId;
    });
    builder.addCase(editJourney.fulfilled, (state, { payload, meta }) => {
      const renamedJourney = state.journeys.find((x) => x.workflowInstanceId === meta.arg.workflowInstanceId);
      const targetJourney = payload.find((item) => item.workflowInstanceId === meta.arg.workflowInstanceId);
      if (renamedJourney) {
        renamedJourney.name = targetJourney.name;
      }
      state.editingId = null;
    });
    builder.addCase(editJourney.rejected, (state) => {
      state.editingId = null;
    });
    builder.addCase(finishJourney.pending, (state, { meta }) => {
      state.stoppingId = meta.arg.finishedJourney.workflowInstanceId;
    });
    builder.addCase(finishJourney.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
      state.stoppingId = null;
    });
    builder.addCase(finishJourney.rejected, (state) => {
      state.stoppingId = null;
    });
    builder.addCase(refactorStateServiceJourney.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
    });
    builder.addCase(editActionJourney.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
    });
    builder.addCase(refactorConsentServiceJourneys.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
    });
    builder.addCase(editJourneyService.fulfilled, (state, { payload }) => {
      state.journeys = [...payload];
    });
    builder.addCase(setReloadUjoPage, (state, action) => {
      state.reloadUjoPage = action.payload;
    });
  },
});

export default journeysSlice.reducer;
