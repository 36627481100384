import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFeedback, IJourney, IUserStories } from '@app/interfaces/interfaces';
import { getFeedbacksApi, sendFeedbackApi, sendFeedbackApprovalStatusApi } from '@app/api/http/feedback.api';

interface FeedbackSlice {
  loading: boolean;
  loadingSend: boolean;
  successful: boolean;
  feedbacks: IUserStories[];
  emitOpen: boolean;
  finishedJourney: IJourney | null;
}

const initialState: FeedbackSlice = {
  loading: false,
  loadingSend: false,
  successful: false,
  feedbacks: [],
  emitOpen: false,
  finishedJourney: null,
};

export const sendFeedback = createAsyncThunk(
  'feedback/sendFeedback',
  async (feedbackPayload: IFeedback, { dispatch, rejectWithValue }) =>
    sendFeedbackApi(feedbackPayload)
      .then((res) => {
        dispatch(getFeedbacks());
        return res.data;
      })
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const sendFeedbackApprovalStatus = createAsyncThunk(
  'feedback/sendFeedbackApprovalStatus',
  async (feedbackPayload: IFeedback, { dispatch, rejectWithValue }) =>
    sendFeedbackApprovalStatusApi(feedbackPayload)
      .then((res) => {
        dispatch(getFeedbacks());
        return res.data;
      })
      // .catch((error) => rejectWithValue(error.response?.data?.message || error.message || error.toString())),
      .catch((error) =>
        rejectWithValue(
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
        ),
      ),
);

export const getFeedbacks = createAsyncThunk('feedback/getFeedbacks', async (_, { rejectWithValue }) =>
  getFeedbacksApi()
    .then((res) => res.data)
    .catch((error) =>
      rejectWithValue(
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString(),
      ),
    ),
);

export const emitOpen = createAsyncThunk('feedback/emitOpen', (journey: IJourney | null) =>
  Promise.resolve().then(() => console.log('Feedback on finished journey :', journey)),
);

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, (state) => {
      state.loadingSend = true;
      state.successful = false;
    });
    builder.addCase(sendFeedback.fulfilled, (state) => {
      state.loadingSend = false;
      state.successful = true;
    });
    builder.addCase(sendFeedback.rejected, (state) => {
      state.loadingSend = false;
    });
    builder.addCase(getFeedbacks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeedbacks.fulfilled, (state, action) => {
      state.loading = false;
      state.feedbacks = action.payload;
    });
    builder.addCase(getFeedbacks.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(emitOpen.pending, (state, action) => {
      state.emitOpen = action.meta.arg ? true : false;
    });
    builder.addCase(emitOpen.fulfilled, (state, action) => {
      state.emitOpen = false;
      state.finishedJourney = action.meta.arg;
    });
    builder.addCase(sendFeedbackApprovalStatus.pending, (state) => {
      state.loadingSend = true;
      state.successful = false;
    });
    builder.addCase(sendFeedbackApprovalStatus.fulfilled, (state) => {
      state.loadingSend = false;
      state.successful = true;
    });
    builder.addCase(sendFeedbackApprovalStatus.rejected, (state) => {
      state.loadingSend = false;
    });
  },
});

export default feedbackSlice.reducer;
