import { Modal } from '@app/components/common/Modal/Modal';
import { Form } from 'antd';
import styled from 'styled-components/macro';

export const ModalWrapper = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }

  &.privacyModal {
    overflow-y: auto;
    height: 85vh;
  }
`;

export const Footer = styled.div`
  display: none;
  height: 0px !important;
`;

export const FeedbackPrompt = styled.div`
  font-size: small;
  margin-bottom: 15px;
`;

export const FormWrapper = styled(Form)`
  .feedbackButtons {
    margin-bottom: -20px;
    margin-top: 45px;
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-input-textarea-show-count::after,
  .ant-input-textarea-in-form-item::after {
    color: var(--text-main-color);
  }

  .policy {
    float: left;
  }
`;
