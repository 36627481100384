import { TextArea } from '@app/components/common/Input/Input';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './FeedbackModal.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { sendFeedback } from '@app/store/slices/feedbackSlice';
// import { notificationController } from '@app/controllers/notificationController';
import { Select } from '@app/components/common/selects/Select/Select';
import { capitalize } from 'lodash';
import { IDropdown } from '@app/interfaces/interfaces';
import { Form, Tooltip } from 'antd';
import PrivacyPolicyText from '@app/components/common/References/PrivacyPolicy/PrivacyPolicyText';
import { modalSizes } from '@app/constants/modalSizes';
import { BREAKPOINTS } from '@app/styles/themes/constants';
import { FaArrowLeft } from 'react-icons/fa';
import { countryLocale } from '@app/utils/utils';
import i18n from '@app/i18n';
import { fetchJourneys } from '@app/store/slices/journeysSlice';

const FeedbackModal = forwardRef<{ setOpen: () => void }, { auto?: boolean }>(({ auto }, ref) => {
  const { t } = useTranslation();
  const [isBasicModalVisible, setIsBasicModalVisible] = useState<boolean>(false);
  const { loadingSend, emitOpen } = useAppSelector((state) => state.feedback);
  const [feedback, setFeedback] = useState<string>('');
  const user = useAppSelector((state) => state.userInfo.user);
  const { journeys, loading: loadingUserJourneys } = useAppSelector((state) => state.journeys);
  const { finishedJourney, loading: loadingFeedback } = useAppSelector((state) => state.feedback);

  const [form] = Form.useForm();

  const [origin, setOrigin] = useState<string>();
  const [originOptions, setOriginOptions] = useState<IDropdown[]>([]);
  const [openOrigin, setOpenOrigin] = useState<boolean>(false);
  const [purpose, setPurpose] = useState<string>();
  const [purposeOptions, setPurposeOptions] = useState<IDropdown[]>([]);
  const [openPurpose, setOpenPurpose] = useState<boolean>(false);
  const [destination, setDestination] = useState<string>();
  const [openDestination, setOpenDestination] = useState<boolean>(false);
  const [destinationOptions, setDestinationOptions] = useState<IDropdown[]>([]);
  const [originsLocale, setOriginsLocale] = useState<IDropdown[]>([]);
  const [destinationsLocale, setDestinationsLocale] = useState<IDropdown[]>([]);
  const [purposeLocale, setPurposeLocale] = useState<IDropdown[]>([]);
  const [policyOpened, setPolicyOpened] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isBasicModalVisible) {
      setOrigin(undefined);
      dispatch(fetchJourneys());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasicModalVisible]);

  const getUserDetails = () => {
    const userName =
      user?.data?.preferredUsername && user.data.preferredUsername.trim() !== ''
        ? user.data.preferredUsername.trim()
        : (user?.data?.givenName ?? '') + ' ' + (user?.data?.familyName ?? '');

    const nameSplit = userName.split(' ');
    if (nameSplit.length > 1) {
      return {
        initials: (nameSplit[0].charAt(0) + nameSplit[1].charAt(0)).toUpperCase(),
        name: user?.data?.givenName ?? '',
        surname: user?.data?.familyName ?? '',
        email: user?.data?.email ?? '',
      };
    } else {
      return {
        initials: userName.length > 1 ? userName.slice(0, 2).toUpperCase() : userName.toUpperCase(),
        name: user?.data?.givenName ?? '',
        surname: user?.data?.familyName ?? '',
        email: user?.data?.email ?? '',
      };
    }
  };

  const capitalizeCountry = (countryName: string, toLocale: boolean) => {
    if (countryName.indexOf('-') === 3) {
      return toLocale ? capitalize(t(countryLocale(countryName.substring(4)))) : capitalize(countryName.substring(4));
    } else {
      return toLocale ? capitalize(t(countryLocale(countryName))) : capitalize(countryName);
    }
  };

  const showCountryLocale = (countryArray: IDropdown[]) => {
    const locale: IDropdown[] = [];
    if (countryArray && i18n.language !== 'en') {
      countryArray.forEach((country) => {
        if (country?.value && country.value.trim() !== '') {
          locale.push({
            value: country.value,
            label: capitalizeCountry(country.value, true),
          });
        }
      });
      return locale.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      return countryArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  };

  const getOrigins = () => {
    if (!finishedJourney) {
      if (journeys?.length > 0) {
        setOriginOptions(
          Array.from(new Set(journeys.map((x) => x?.countryOrigin?.toLowerCase()))).map((y) => ({
            label: capitalizeCountry(y.toString(), false),
            value: y,
          })) || [],
        );
      } else {
        setOriginOptions([]);
        setOrigin(undefined);
      }
    }
  };

  useEffect(() => {
    setOriginsLocale(showCountryLocale(originOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originOptions, i18n.language]);

  useEffect(() => {
    setDestinationsLocale(showCountryLocale(destinationOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationOptions, i18n.language]);

  useEffect(() => {
    if (purposeOptions) {
      const locale: IDropdown[] = [];
      purposeOptions.forEach((type) => {
        if (type.value.toLowerCase() === 'study') {
          locale.push({ value: type.value, label: t('common.studies', { defaultValue: 'Studies' }) });
        } else if (type.value.toLowerCase() === 'work') {
          locale.push({ value: type.value, label: t('common.work', { defaultValue: 'Work' }) });
        }
      });
      setPurposeLocale(locale.sort((a, b) => a.label.localeCompare(b.label)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purposeOptions, i18n.language]);

  useEffect(() => {
    if (!finishedJourney) {
      setPurpose(undefined);
      setDestination(undefined);
      form.setFieldValue('purpose', undefined);
      form.setFieldValue('destination_country', undefined);
      setPurposeOptions(
        Array.from(
          new Set(
            journeys
              .filter((w) => w.countryOrigin.toLowerCase() === origin?.toLowerCase())
              .map((x) => x.workflowType.toLowerCase()),
          ),
        ).map((y) => ({
          label: capitalize(y),
          value: y,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);

  useEffect(() => {
    if (!finishedJourney) {
      setDestination(undefined);
      form.setFieldValue('destination_country', undefined);
      setDestinationOptions(
        Array.from(
          new Set(
            journeys
              .filter(
                (w) =>
                  w.countryOrigin.toLowerCase() === origin?.toLowerCase() &&
                  w.workflowType.toLowerCase() === purpose?.toLowerCase(),
              )
              .map((x) => x.countryDestination.toLowerCase()),
          ),
        ).map((y) => ({
          label: capitalizeCountry(y.toString(), false),
          value: y,
        })) || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purpose]);

  useEffect(() => {
    getOrigins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeys]);

  const toggleOrigin = () => {
    setOpenOrigin(!openOrigin);
    setOpenPurpose(false);
    setOpenDestination(false);
  };

  const togglePurpose = () => {
    setOpenPurpose(!openPurpose);
    setOpenOrigin(false);
    setOpenDestination(false);
  };

  const toggleDestination = () => {
    setOpenDestination(!openDestination);
    setOpenOrigin(false);
    setOpenPurpose(false);
  };

  const onSendFeedback = () => {
    const { name, surname, initials, email } = getUserDetails();
    dispatch(
      sendFeedback({
        origin: origin?.toUpperCase() || '',
        purpose: purpose?.toUpperCase() || '',
        destination: destination?.toUpperCase() || '',
        text: feedback,
        userInitials: initials,
        userFirstName: name,
        userSurname: surname,
        userEmail: email,
        approve: 'pending',
      }),
    ).finally(() => {
      setIsBasicModalVisible(false);
      form.resetFields();
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = e.target.value;
    setFeedback(text?.trim());
  };

  // useEffect(() => { //TODO: if put back, check why it appears twice
  //   if (successful) {
  //     notificationController.success(t('notifications.feedback', { defaultValue: 'We got your feedback!' }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successful]);

  useEffect(() => {
    if (emitOpen && auto) setIsBasicModalVisible(true);
  }, [auto, emitOpen]);

  useImperativeHandle(ref, () => ({
    setOpen() {
      setIsBasicModalVisible(true);
    },
  }));

  useEffect(() => {
    if (finishedJourney) {
      setOriginOptions([
        {
          label: capitalizeCountry(finishedJourney.countryOrigin?.toLowerCase(), false),
          value: finishedJourney.countryOrigin.toLowerCase(),
        },
      ]);
      setPurposeOptions([
        {
          label: capitalize(finishedJourney.workflowType.toLowerCase()),
          value: finishedJourney.workflowType.toLowerCase(),
        },
      ]);
      setDestinationOptions([
        {
          label: capitalizeCountry(finishedJourney.countryDestination.toLowerCase(), false),
          value: finishedJourney.countryDestination.toLowerCase(),
        },
      ]);
      form.setFieldsValue({
        origin_country: finishedJourney.countryOrigin.toLowerCase(),
        purpose: finishedJourney.workflowType.toLowerCase(),
        destination_country: finishedJourney.countryDestination.toLowerCase(),
      });
      setOrigin(finishedJourney.countryOrigin.toLowerCase());
      setPurpose(finishedJourney.workflowType.toLowerCase());
      setDestination(finishedJourney.countryDestination.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedJourney]);

  return (
    <S.ModalWrapper
      forceRender
      title={
        !policyOpened
          ? t('common.modals.feedback', { defaultValue: 'Share your feedback!' })
          : t('common.modals.privacyPolicyTitle', { defaultValue: 'ACROSS Platform Privacy Policy' })
      }
      open={isBasicModalVisible}
      onOk={() => {
        setIsBasicModalVisible(false);
      }}
      onCancel={() => {
        setIsBasicModalVisible(false);
      }}
      maskClosable={false}
      width={policyOpened ? (windowWidth < BREAKPOINTS.sm ? '90vw' : '80vw') : modalSizes.small}
      footer={<></>}
      afterClose={() => {
        form.resetFields();
        setIsBasicModalVisible(false);
        setPolicyOpened(false);
      }}
      className={policyOpened ? 'privacyModal' : ''}
    >
      <div style={policyOpened ? { display: 'block' } : { display: 'none' }}>
        <br />
        <Button type="link" size="small" icon={<FaArrowLeft />} onClick={() => setPolicyOpened(false)}>
          {t('common.modals.backToFeedback', { defaultValue: 'Go back to Feedback Menu' })}
        </Button>
        <br />
        <PrivacyPolicyText />
        <br />
        <Button
          type="link"
          size="small"
          icon={<FaArrowLeft />}
          onClick={() => setPolicyOpened(false)}
          style={{ float: 'right' }}
        >
          {t('common.modals.backToFeedback', { defaultValue: 'Go back to Feedback Menu' })}
        </Button>
        <br />
        <br />
      </div>

      <div style={policyOpened ? { display: 'none' } : { display: 'block' }}>
        <S.FeedbackPrompt
          dangerouslySetInnerHTML={{
            __html: t('common.modals.feedbackPrompt', {
              defaultValue:
                'Share your experiences and tips with other travelers! (Your feedback and your initials will show up on the first page of the platform)',
            }),
          }}
        ></S.FeedbackPrompt>

        <S.FormWrapper
          layout="vertical"
          form={form}
          name={`feedback_form`}
          onFinish={() => onSendFeedback()}
          autoComplete="off"
        >
          <Form.Item
            name="origin_country"
            label={t('common.originCountry', { defaultValue: 'Origin Country' })}
            rules={[{ required: true }]}
          >
            {originsLocale?.length > 0 ? (
              <Select
                aria-label={t('common.originCountry', { defaultValue: 'Origin Country' })}
                placeholder={t('dashboard.selectOrigin', { defaultValue: 'Select an origin' })}
                loading={loadingUserJourneys || loadingFeedback}
                onChange={(val) => setOrigin(val as string)}
                options={originsLocale}
                allowClear
                width="100%"
                value={origin}
                open={openOrigin}
                onClick={toggleOrigin}
                disabled={finishedJourney || originsLocale?.length === 0 ? true : false}
              />
            ) : (
              <Tooltip
                title={t('common.modals.feedbackNotAllowed', {
                  defaultValue: 'You need to have started a journey before leaving your feedback',
                })}
                zIndex={10000}
              >
                <Select
                  aria-label={t('common.originCountry', { defaultValue: 'Origin Country' })}
                  placeholder={t('dashboard.selectOrigin', { defaultValue: 'Select an origin' })}
                  loading={loadingUserJourneys || loadingFeedback}
                  width="100%"
                  disabled
                />
              </Tooltip>
            )}
          </Form.Item>
          <Form.Item
            name="purpose"
            label={t('dashboard.purpose', { defaultValue: 'Purpose' })}
            rules={[{ required: true }]}
          >
            {origin || finishedJourney ? (
              <Select
                placeholder={t('dashboard.selectPurpose', { defaultValue: 'Select some purpose' })}
                loading={loadingUserJourneys || loadingFeedback}
                onChange={(val) => setPurpose(val as 'STUDY' | 'WORK')}
                options={purposeLocale}
                allowClear
                width="100%"
                value={purpose}
                open={openPurpose}
                onClick={togglePurpose}
                disabled={finishedJourney ? true : false}
                aria-label={t('dashboard.purpose', { defaultValue: 'Purpose' })}
              />
            ) : (
              <Tooltip
                title={
                  originsLocale?.length === 0
                    ? t('common.modals.feedbackNotAllowed', {
                        defaultValue: 'You need to have started a journey before leaving your feedback',
                      })
                    : t('dashboard.purposeTooltip', { defaultValue: 'Please select an origin first' })
                }
                zIndex={10000}
              >
                <Select
                  placeholder={t('dashboard.selectPurpose', { defaultValue: 'Select some purpose' })}
                  loading={loadingUserJourneys || loadingFeedback}
                  width="100%"
                  disabled
                  aria-label={t('dashboard.purpose', { defaultValue: 'Purpose' })}
                />
              </Tooltip>
            )}
          </Form.Item>
          <Form.Item
            name="destination_country"
            label={t('common.destinationCountry', { defaultValue: 'Destination Country' })}
            rules={[{ required: true }]}
          >
            {(origin && purpose) || finishedJourney ? (
              <Select
                aria-label={t('common.destinationCountry', { defaultValue: 'Destination Country' })}
                dropdownMatchSelectWidth={false}
                placeholder={t('dashboard.selectDestination', { defaultValue: 'Select a destination' })}
                loading={loadingUserJourneys || loadingFeedback}
                onChange={(val) => setDestination(val as string)}
                options={destinationsLocale}
                allowClear
                width="100%"
                value={destination}
                open={openDestination}
                onClick={toggleDestination}
                disabled={finishedJourney ? true : false}
              />
            ) : (
              <>
                {!origin ? (
                  <Tooltip
                    title={
                      originsLocale?.length === 0
                        ? t('common.modals.feedbackNotAllowed', {
                            defaultValue: 'You need to have started a journey before leaving your feedback',
                          })
                        : t('dashboard.destinationTooltip1', {
                            defaultValue: 'Please select an origin and the purpose of the journey first',
                          })
                    }
                    zIndex={10000}
                  >
                    <Select
                      aria-label={t('common.destinationCountry', { defaultValue: 'Destination Country' })}
                      placeholder={t('dashboard.selectDestination', { defaultValue: 'Select a destination' })}
                      loading={loadingUserJourneys || loadingFeedback}
                      width="100%"
                      disabled
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t('dashboard.destinationTooltip2', {
                      defaultValue: 'Please select the purpose of the journey first',
                    })}
                    zIndex={10000}
                  >
                    <Select
                      aria-label={t('common.destinationCountry', { defaultValue: 'Destination Country' })}
                      placeholder={t('dashboard.selectDestination', {
                        defaultValue: 'Please select the purpose of the journey first',
                      })}
                      loading={loadingUserJourneys || loadingFeedback}
                      width="100%"
                      disabled
                    />
                  </Tooltip>
                )}
              </>
            )}
          </Form.Item>
          <Form.Item
            name="feedback_text"
            label={t('header.feedback', { defaultValue: 'Feedback' })}
            rules={[{ required: true }]}
          >
            <TextArea
              id="feedback_form_feedback_text"
              value={feedback.trim()}
              onChange={onChange}
              allowClear
              placeholder={t('common.modals.experience', { defaultValue: 'Describe your experience!' })}
              rows={5}
              showCount
              maxLength={500}
              disabled={originsLocale?.length === 0 ? true : false}
            />
          </Form.Item>

          <Button size="small" type="link" className="policy" onClick={() => setPolicyOpened(true)}>
            {t('common.policy', { defaultValue: 'Privacy Policy' })}
          </Button>

          <Form.Item className="feedbackButtons">
            <Button
              id="share_feedback_cancel_btn"
              size="small"
              htmlType="button"
              onClick={() => setIsBasicModalVisible(false)}
            >
              {t('common.modals.cancel', { defaultValue: 'Cancel' })}
            </Button>

            <Button
              id="share_feedback_submit_btn"
              loading={loadingSend}
              htmlType="submit"
              type="primary"
              size="small"
              disabled={!form.isFieldsTouched(true) || feedback?.trim() === ''}
              style={{ marginLeft: '5px' }}
            >
              {t('common.submit', { defaultValue: 'Submit' })}
            </Button>
          </Form.Item>
        </S.FormWrapper>
      </div>
    </S.ModalWrapper>
  );
});

export default FeedbackModal;
