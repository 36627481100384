import { Divider } from 'antd';
import * as S from './CodeOfConductText.styles';
import logo from 'assets/logo-dark.png';
import React from 'react';

const CodeOfConductText: React.FC = () => {
  return (
    <S.TextWrapper>
      <>
        <S.ImgContainer>
          <img src={logo} alt="Across" width="170px" style={{ marginBottom: '20px' }}></img>
        </S.ImgContainer>
        <h4>1.INTRODUCTION </h4>
        <p>
          1.1. The ACROSS platform is a user-centric platform/gateway for European citizens to find and interact with
          trustworthy national governmental services and private sector Service Providers (hereinafter referred to as
          “Service Providers”) in cross-border scenarios (i.e. “studying abroad” and “moving abroad”). Through the
          ACROSS platform public administrations and/or private sector Service Providers can gain access to personal
          data of Citizen Users, based on the consent of that user.
        </p>
        <p>
          1.2. The ACROSS platform aims to enable data sharing with a potentially unlimited range of Service Providers,
          including public sector and private sector Service Providers. Public sector Service Providers are typically
          considered trustworthy to a significant extent, based on the fact that they must operate within the limits of
          a legally defined mandate. Private sector Service Providers are however not subject to the same constraints
          with regards to the processing of personal data of citizens. For that reason, the ACROSS operator has
          identified the need to implement additional measures in place, particularly to ensure the privacy of the data
          shared by users through the ACROSS Platform. This Code of Conduct is one such measure.
        </p>
        <p>
          1.3. Service Providers are independent data controllers (distinct from the ACROSS operator) in relation to the
          data they receive through the ACROSS platform. In simpler terms, the ACROSS operator ensures the proper
          operation of the platform, including by technically supporting any connections to the Service Providers; and
          the Service Providers use the data they receive for the purposes they’ve communicated to the user. In that
          sense, the Service Providers have their own responsibilities under data protection legislation. However, as a
          part of the ethics requirements in developing the ACROSS platform, the project partners have identified a need
          to set a minimum level of data protection that must be respected by every user of the ACROSS platform. This is
          done in practice by requiring Service Providers (whether public or private) to agree to respect the provisions
          of this Code of Conduct. In this way, ethical behaviour is more likely to occur at the Service Providers’
          side, since they are contractually bound by the provisions of this Code, both towards the ACROSS operator and
          towards the ACROSS platform users.{' '}
        </p>
        <p>
          1.4. The ACROSS platform is designed with privacy and data protection in mind, in accordance with the privacy
          by design and the privacy by default principles set out in the General Data Protection Regulation (hereinafter
          referred to as the GDPR). This Code of Conduct has been developed with these principles in mind: it provides
          an effective tool to ensure that Service Providers process personal data of Citizen Users in compliance with
          data protection legislation, and that they are following the general ACROSS data protection framework.
        </p>
        <h4>2. THE PURPOSE AND SCOPE OF APPLICATION OF THIS CODE</h4>
        <p>
          2.1. The purpose of this Code is to foster justified trust among the Citizen Users (i.e. any natural persons
          using the platform, irrespective of their nationality or place of establishment) of the ACROSS Platform, by
          ensuring an appropriately high standard of data protection for any data processing activities that occur by
          Service Providers, even outside of the perimeter of the Platform. The Code aims to fulfil this goal by
          providing guarantees to ensure the proper application of the GDPR principles and general good conduct
          principles.
        </p>
        <p>
          2.2. Crucially, this Code of Conduct and its principles apply to data processing activities not only in direct
          interaction with the Platform (i.e. when Service Providers request data via the Platform, or receive it via
          the Platform), but also to processing activities outside of the Platform (i.e. for processing activities
          undertaken after the Service Provider has received the data, including processing activities on its own
          infrastructure). In all instances, the provisions of the Code are binding, except where applicable and binding
          legislation requires the Service Provider to disregard the terms of the Code (i.e. if a Service Provider,
          whether public or private sector, is required by applicable laws to engage in processing activities or other
          behaviours that are not permissible under this Code, applicable laws will take precedence).
        </p>
        <p>
          2.3. This Code applies to any Service Providers that are using the ACROSS platform to provide their services.
          Adherence to the Code by these Service Providers is a condition to use the ACROSS platform. Every Service
          Provider must submit a legally binding declaration of compliance to the ACROSS platform operator before
          registering to the platform. Annex I provides a template for a self-declaration of compliance to this Code of
          Conduct, which must be accepted by the Service Provider.
        </p>
        <h4>3. GENERAL RULES OF CONDUCT</h4>
        <p>
          3.1. The present section will define behaviours that are considered unacceptable when using the ACROSS
          platform.{' '}
        </p>
        <p>
          3.2. The following behaviours are <strong>forbidden</strong>:
        </p>
        <p className="list">
          ● Service Providers may not directly or indirectly discriminate citizens based on their nationality, race or
          ethnicity, background, family status, gender identity or expression, marital status, sex or sexual
          orientation, age, (dis)ability, socioeconomic status, religion, geographic location, experience, etc.
          <br />
          <br /> ● Service Providers may not use the ACROSS platform to enable the spread of false information and/or
          hate speech towards people or other organizations; <br />
          <br /> ● Service Providers may not hack the ACROSS platform and will not use the ACROSS platform for acts that
          may result in illegal access to data from linked sources, which would impair the proper functioning of the
          ACROSS platform or the jeopardizing the use of available functionalities to other Citizen Users;
          <br />
          <br /> ● Service Providers cannot use the ACROSS name, logo, work or other images in an inappropriate way,
          which includes any missleading use (such as suggesting any partnership or support of the ACROSS operator) and
          any violation of any applicable intellectual property laws.
          <br />
          <br /> ● It goes without saying that, in addition to this Code, any action or inaction that is unlawful under
          EU law or under any national law that applies to the Service Provider is also forbidden, even if the terms of
          this Code of Conduct might suggest otherwise.
        </p>
        <p>
          3.3. Service Providers must also ensure the accuracy of the information provided by them on the ACROSS
          platform, e.g. when providing URLs on the ACROSS platform where citizens can check their application for
          services, the Service Provider must ensure that this URL is valid, and that it is update as needed. They must
          periodically check the URL on any suspicion of phishing or hacking.
        </p>
        <p>
          3.4. Service Providers must notify any identified improper or unlawful use of the ACROSS platform of which
          they become aware, e.g. hacking, presence of illegal content on the ACROSS platform, presence of
          discrimination by other Service Providers on the ACROSS platform, etc. by sending an e-mail to{' '}
          <a href="mailto: acrossprojecteu@gmail.com">acrossprojecteu@gmail.com</a> so that ACROSS can take necessary
          and appropriate action if necessary.
        </p>
        <h4>4. DATA PROTECTION PRINCIPLES</h4>
        <p>
          4.1. <strong>Principle of purpose limitation</strong>: Service Providers using the ACROSS platform must
          respect the principle of purpose limitation. This means that the personal data of the Citizen User that is
          shared via the ACROSS platform can only be used for the purposes of providing the services that the Citizen
          User has selected on the platform and which have been clearly communicated to the citizen in the service
          description on the ACROSS platform.
          <br /> Service Providers may only use the data provided by the Citizen User for purposes compatible with those
          for which the personal data have been initially collected or for which the Citizen User has decided to make
          them available to the Service Provider. The Service Provider must in this case take into account the
          following:
          <br />
        </p>
        <p className="list">
          ● The possible link between the purposes;
          <br />
          <br />● The context in which the personal data have been collected, in particular taking into account the
          relationship with the data subjects and the usage disclosed to the Citizen User;
          <br />
          <br /> ● The nature of the personal data (specifically its sensitivity under EU or national law, but also more
          generally its privacy sensitivity to a reasonably diligent person);
          <br /> <br />● The possible consequences of the intended further processing for data subjects; and <br />
          <br />● The existence of appropriate safeguards.
        </p>
        <p>
          4.2. <strong>Principle of data minimisation</strong>: Service Providers that are using the ACROSS platform
          must carefully consider what data is strictly necessary for the provision of the services. Only the personal
          data that is strictly necessary for the provision of the service can be requested as mandatory data by the
          Service Provider through the ACROSS platform. Service Providers may not collect or process more data or use
          the data for a longer duration than is strictly necessary to achieve this purpose.
        </p>
        <p>
          4.3. <strong>Principle of transparency</strong>: Service Providers must provide the Citizen Users with clear
          information about the personal data they are processing, the purposes of this data processing, the legal basis
          and any third parties with whom they will share the personal data. The Citizen Users must also be provided
          with information about their data subject rights and the way in which they can exercise those rights. The
          Service Provider shall make sure that the information that is provided to the Citizen Users is sufficiently
          understandable and clear.
        </p>
        <p>
          4.4. <strong>Principle of storage limitation</strong>: the Service Provider shall ensure that the personal
          data of Citizen Users is processed no longer than is necessary for the provision of the services and in any
          case in accordance with mandatory retention periods set forth in applicable legislations. Service Providers
          must establish time limits for erasure or for a period review of the personal data they store or process.
          Wherever this is technically, operationally and legally feasible, the Service Provider shall retain the
          Citizen User’s data only for a minimal amount of time as needed to provide the services communicated to the
          user, without storing that Citizen User’s data after the service has been provided. In other words, the
          Service Provider acknowledges that they are legally required under this Code to attempt to use the ACROSS
          Platform to access the Citizen User’s data dynamically during each use of the data, without storing it outside
          of the ACROSS Platform whenever this is feasible. The ACROSS Platform endeavours to be a dynamic data sharing
          platform from which data can be accessed when needed, not a source from which data is copied permanently.
        </p>
        <h4>5. RULES ON INTERNATIONAL TRANSFERS OF THE CITIZENS PERSONAL DATA </h4>
        <p>
          5.1. In the case of transfers of citizens personal data to countries outside the European Economic Area (EEA,
          i.e. the EU Member States, Norway, Iceland and Liechtenstein), the Service Provider shall be responsible for
          ensuring the existence of any safeguards according to Chapter V of the GDPR, and shall take into account
          applicable judgements (such as the Schrems II Court of Justice Judgement) and EDPB guidelines, notably
          Recommendations 01/2020 on measures that supplement transfer tools to ensure compliance with the EU level of
          protection of personal data. If the Service Provider is unable to do so for whatever reason, it must refrain
          from using the ACROSS Platform as a source to access personal data.
        </p>
        <p>
          5.2. Transfers to third countries shall only take place when strictly necessary for the provision of the
          service. When a Service Provider would rely on sub-processors that are established outside the EEA, the
          Service Provider shall take reasonable steps to search for an EU alternative. If no alternative is available,
          the Service Provider shall make sure that the international transfer takes place only if the conditions in
          Chapter V GDPR are met. This means that one or more of the following conditions must be satisfied:{' '}
        </p>
        <p className="list">
          ● The locations are countries which are covered by an adequacy decision of the European Commission
          <sup>1</sup> ; <br />
          <br />● The third party has provided appropriate contractual guarantees through the European Commission’s
          Standard Contractual Clauses<sup>2</sup> for the transfer of personal data to third countries, or through the
          conclusion of Binding Corporate Rules (BCRs);
          <br /> <br />● Transfers are permissible if the Service Provider has obtained the Citizen User’s unambiguous
          consent to the proposed transfer. However, transfer is only a valid ground for occasional and clearly
          identified transfers, not for repeated or structural transfers. If the Service Provider intents to
          systematically and frequently transfer data to a location outside of the EU/EEA, one of the two aforementioned
          conditions should be satisfied.
          <br />
        </p>
        <h4>6. RIGHTS OF THE DATA SUBJECT </h4>
        <p>
          6.1. Service Providers using the ACROSS platform acknowledge that the Citizen Users have the following data
          subject rights under the GDPR:
        </p>{' '}
        <p className="list">
          ● The right to access the personal data relating to them that the Service Provider is processing, including
          the right to receive a copy of the personal data;
          <br />
          <br />● The right to obtain corrections of the personal data if it is incorrect or outdated; <br />
          <br />● The right to object to any further processing of the personal data by the Service Provider;
          <br /> <br />● The right to request a restriction of the processing; <br />
          <br />● The right to demand a deletion of the personal data; <br />
          <br />● The right to request data portability;
          <br />
          <br />● The right not to be subject to automated decision-making; and <br />
          <br />● The right to submit a complaint to a competent supervisory authority
        </p>
        <p>
          6.2. Service Providers shall ensure that Citizen Users can exercise such rights towards the Service Providers,
          even if European data protection laws do not formally apply to them. Service Providers shall take any actions
          reasonably necessary, and shall act in good faith, to ensure that the exercise of rights by Citizen Users
          towards them is practically feasible in the same manner and under the same conditions as is required under EU
          data protection law.
        </p>
        <p>
          6.3. The ACROSS platform provides means for the Citizen User to exercise his or her data subject rights,
          specifically by providing contact information (an email address) of the data protection officer (DPO) or data
          protection contact point of the Service Provider. This means that each Service Provider that uses the ACROSS
          platform must nominate a data protection officer, when required under the GDPR or under other applicable law.
          When the Service Provider is not obliged to nominate a data protection officer, the Service Provider shall
          instead designate a point of contact for data protection related issues, meeting the requirements of chapter
          IV, section 4 GDPR.
        </p>
        <p>
          6.4. The Service Provider is responsible for responding to the data subject request within the mandatory
          timeframe<sup>3</sup>. The Service Provider shall not refuse to respond to a data subject request except when
          its not in the position to identify the data subject and it has taken reasonable steps to identify the data
          subject (e.g. by asking proof of identity documentation such as a passport or a drivers license).
        </p>
        <p>
          6.5. The Service Provider must have documented procedures in place for fulfilling data subject requests, and
          must ensure that these procedures are adhered to in practice, and that they are effective in responding to
          Citizen User rights request.
        </p>
        <h4>7. TECHNICAL AND ORGANISATIONAL MEASURES </h4>
        <p>
          7.1. Service Providers are required to implement appropriate technical and organisational measures to protect
          personal data against accidental or unlawful destruction, loss, alteration, disclosure, access and other
          unlawful forms of processing.{' '}
        </p>
        <p>
          7.2. The appropriateness of the implemented security measures will be highly dependent on the nature of the
          data that is processed (sensitivity) and its potential impact on the user. At minimum, the Service Provider
          must have the following security measures in place:
          <br />
          <br /> <u>Technical measures</u>:
        </p>
        <p className="list">
          ● Equipment Access Control: deny unauthorised persons access to processing equipment used for processing;
          <br />
          <br /> ● Data Media Control: prevent the unauthorised reading, copying, modification or removal of data media;
          <br />
          <br /> ● Storage Control: prevent the unauthorised input of personal data to unauthorised inspection,
          modification or deletion of stored data;
          <br />
          <br /> ● User Control: prevent the use of automated processing systems by unauthorised persons using data
          communication equipment;
          <br />
          <br /> ● Communication Control: ensure that it is possible to verify and establish the bodies to which
          personal data have been or may be transmitted or made available using data communication equipment;
          <br />
          <br /> ● Input Control: ensure that it is subsequently possible to verify and establish which personal data
          have been input into automated processing systems and when and by whom the personal data were input;
          <br /> <br />● Transport Control: prevent the unauthorised reading, copying, modification or deletion of
          personal data during transfers of personal data or during transportation of data media (by implementing
          encryption controls for any transit of data);
          <br />
          <br /> ● Recovery: ensure that installed systems may, in the case of interruption, be restored;
          <br />
          <br /> ● Ensure that the functions of the system perform, that the appearance of faults in the functions is
          reported (‘reliability’) and that stored personal data cannot be corrupted by means of a malfunctioning of the
          system (‘integrity’).
        </p>
        <p>
          <u> Organizational measures</u>:
        </p>
        <p className="list">
          ● Sub-contracting: the Service Provider must conclude data processing agreements with all the sub-processors
          it contracts with to process the personal data of the Citizen Users. Prior to contracting with a
          sub-processor, the Service Provider must also assess whether their level of compliance with data protection
          legislation is considered appropriate.
          <br /> <br />● Data protection officer or data protection contact point: the Service Provider must nominate a
          data protection officer, when required under the GDPR. When the Service Provider is not obliged to nominate a
          data protection officer the Service Provider shall have a point of contact for data protection related issues,
          meeting the requirements of chapter IV, section 4 GDPR;
          <br />
          <br />● Privacy notices/policies: the Service Provider must have the required privacy notices and general
          privacy policies in place informing the Citizen Users about the processing of their personal data. The privacy
          policy and notices must fulfil the requirements as set forth in Article 13 and 14 of the GDPR.
          <br />
          <br />● Incident response and data breach procedure: the Service Provider must have a incident response and
          data breach procedure in place which explains in detail the steps that need to be taken when a data breach
          occurs and which assigns responsibilities. More information on the data breach procedure can be found under
          clause 7.
        </p>
        <p>
          7.3. The Service Provider must duly document its technical and organisational measures and must keep them
          up-to-date taking into account any changes in legislation, guidance by supervisory authorities or any
          lessons-learned from data breaches that may have occurred. The Service Provider must ensure that in case of
          new or updated technical and organisational measures, the level of protection remains equal as to the measures
          set forth in clause 6.2., and that no less protective measures shall be implemented.{' '}
        </p>
        <h4>8. DATA BREACHES </h4>
        <p>
          8.1. A data breach occurs when any Citizen User’s personal data is subjected to an incident leading to
          accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, that personal
          data.{' '}
        </p>
        <p>
          8.2. The Service Provider must have a clear process in place in case (suspected) data breaches occur. The data
          breach procedure must include at least the following steps:
        </p>
        <p className="list">
          1. The Service Provider must consider whether citizen’s data that has been provided through the ACROSS
          platform is impacted by the data breach, and if yes, which personal data has been impacted.
          <br />
          <br /> 2. The Service Provider shall duly consider whether there is a duty to notify the data breach to any
          supervisory authority in a specific country or countries. The Service Provider shall respect the time frames
          set forth in the GDPR, meaning that it shall make the notification (if mandatory) without undue delay and, in
          any case, no later than 72 hours after having become aware of the breach. The Service Provider shall also
          notify the data breach to the impacted data subjects, if it considers that the breach has a high impact on the
          rights and freedoms of those data subjects.
          <br />
          <br /> 3. The Service Provider must perform a root cause analysis of the data breach and implement as soon as
          possible any mitigating measures to address the data breach and to avoid any further breaches. The lessons
          learned should be used to assess the appropriateness of the implemented security measures.
        </p>
        <h4>9. ENFORCEMENT OF THIS CODE OF CONDUCT </h4>
        <p>
          9.1. Any non-compliance with the general conduct rules and the GDPR principles listed above will not be
          tolerated. Any user of the ACROSS Platform who becomes aware of a non-compliance by any Service Provider using
          the ACROSS platform (including the Service Provider itself) must notify this non-compliance via:
          <a href="mailto: acrossprojecteu@gmail.com"> acrossprojecteu@gmail.com</a>
        </p>
        <p>
          9.2. Any identified non-compliance with the rules of this Code of Conduct will be assessed and investigated by
          the ACROSS platform operator, and appropriate measures will be taken to address the situation. These measures
          will be dependent on the nature, severity and duration of the infringement and the intentional or negligent
          character of the infringement.
        </p>
        <p>
          9.3. The following measures (non-exhaustively) can be taken by the ACROSS operator at its sole discretion:
        </p>
        <p className="list">
          ● Warning;
          <br />
          <br /> ● Request to bring activities in compliance with this Code of Conduct;
          <br />
          <br /> ● Temporarily suspension from the ACROSS platform;
          <br />
          <br /> ● Expulsion from the ACROSS platform;
        </p>
        <Divider style={{ borderTopColor: '#808080' }}></Divider>
        <p style={{ fontSize: '12px' }}>
          <sup>1</sup> The European Commission has recognized the following countries: Andorra, Argentina, Canada
          (commercial organizations), Faroe Islands, Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand, Republic
          of Korea, Switzerland, the United Kingdom and Uruguay.
          <br />
        </p>
        <p style={{ fontSize: '12px' }}>
          <sup>2</sup> The SCCs can be found via de following link:{' '}
          <a
            href="https://commission.europa.eu/publications/standard-contractual-clauses-international-transfers_en"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            https://commission.europa.eu/publications/standard-contractual-clauses-international-transfers_en
          </a>
          .
        </p>
        <p style={{ fontSize: '12px' }}>
          <sup>3</sup> Article 12 (3) GDPR sets a timeframe of one month after receipt of the data subject request. That
          period may be extended by two further months where necessary, taking into account the complexity and the
          number of request the controller has received. The extension must be notified to the data subject within one
          month after receipt of the data subject request and must be notified.
        </p>
        <Divider style={{ borderTopColor: '#808080' }}></Divider>
        <h4 style={{ textAlign: 'center' }}>ANNEX I: SELF-DECLARATION OF COMPLIANCE FORM </h4>
        <p>
          By signing this self-declaration of compliance form, I declare, on behalf of the entity that I lawfully
          represent, that I have read and understood this Code of Conduct. I also declare that I will comply with the
          obligations and measures set forth in this Code of Conduct, and that I shall ensure that these obligations and
          measures shall also be respected within the entity that I lawfully represent.
        </p>
        <p className="list">
          Name: […] <br />
          Function: […]
          <br />
          Signature: […] <br />
        </p>
      </>
    </S.TextWrapper>
  );
};

export default CodeOfConductText;
