import styled from 'styled-components/macro';

export const TableWrapper = styled.div``;

export const TextWrapper = styled.div`
  color: var(--black);
  p,
  span {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-main-color);
  }
  h4 {
    font-weight: bolder !important;
    font-size: 15px !important;
  }

  .ant-table-pagination,
  .ant-pagination {
    display: none;
  }

  .operatorTable {
    .ant-table-thead {
      display: none;
    }
  }

  .list {
    margin-left: 15px;
  }
  .align-top {
    vertical-align: top;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;
