import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Error.styles';
import { Link } from 'react-router-dom';

interface ErrorProps {
  img: string;
  msg: string;
  full?: boolean;
}

export const Error: React.FC<ErrorProps> = ({ img, msg, full }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper $full={full}>
      <S.Image preview={false} src={img} />
      <S.ContentWrapper>
        <S.Title>{t('common.oops', { defaultValue: 'Oops!' })}</S.Title>
        <S.Text>{msg}</S.Text>
        {/*TODO make common component */}
        <Link to="/app" className="ant-btn ant-btn-link">
          {t('error404.comeBack', { defaultValue: 'Return to Home Page' })}
        </Link>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
