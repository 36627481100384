import styled from 'styled-components/macro';
import { Menu as AntMenu } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Menu = styled(AntMenu)`
  background: transparent;
  border-right: 0;
  padding-left: 8px;
  padding-right: 8px;

  &.ant-menu-inline-collapsed {
    li {
      svg {
        margin: 20px -4px !important;
      }
    }
  }

  li {
    border-radius: 6px !important;
    height: 64px !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.12) !important;
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.12) !important;
  }

  .ant-menu-item-selected::after {
    border-right-color: transparent !important;
  }

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item {
    margin-bottom: 5px !important;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .sidemenuIcon {
    font-size: 25px !important;
    width: 25px !important;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item,
  .ant-menu-submenu {
    color: var(--text-sider-secondary-color);
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: var(--text-sider-primary-color);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--text-sider-primary-color);

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--text-sider-primary-color);
      }
    }
  }

  .ant-menu-item-selected {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-sider-primary-color);
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
  }

  .breakWords {
    white-space: pre-line;
    line-height: 25px;
  }

  li#admin_button.ant-menu-item-disabled {
    display: none;
  }

  #admin_button {
    .admin-notification {
      color: white;
      line-height: 1;
      background: #fc2828cf;
      border-radius: 50%;
      position: absolute;
      left: 40px;
      padding: 3px;
      font-size: 0.6rem;
      top: 11px;
      min-width: 1rem;
      min-height: 1rem;
      padding: 0.3rem;
      text-align: center;

      :empty {
        min-width: 0;
        min-height: 0;
        padding: 0;
      }
    }
  }
`;
