import i18n from '@app/i18n';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { KeycloakInstance } from 'keycloak-js';
import { set } from 'local-storage';

interface authLogin {
  keycloak: KeycloakInstance;
  pathname: string;
}

const initialState = {};

export const doLogin = createAsyncThunk('auth/doLogin', async (auth: authLogin, {}) => {
  const { keycloak, pathname } = auth;

  // keycloak.login({ locale: i18n.language === 'gr' ? 'el' : i18n.language });

  // open keycloak login page in a new tab
  const locale = i18n.language === 'gr' ? 'el' : i18n.language;
  const keycloakUrl = keycloak.createLoginUrl();
  window.open(keycloakUrl + '&ui_locales=' + locale, '_blank');

  set<string>('redirectUrl', pathname);
});

export const doLogout = createAsyncThunk('auth/doLogout', (keycloak: KeycloakInstance, {}) => keycloak.logout());

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

export default authSlice.reducer;
